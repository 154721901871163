import {
  Root,
  Trigger,
  Anchor,
  Content,
  Arrow,
  Close,
  PopoverContentProps,
} from '@radix-ui/react-popover';

import type { ReactNode } from 'react';
import type { PopoverProps } from '@radix-ui/react-popover';

import { CloseIconButton } from 'ui';

import style from './Popover.module.css';

export const Popover = ({
  children,
  trigger,
  anchor,
  defaultOpen,
  open,
  onOpenChange,
  color,
  ...contentProps
}: {
  children: ReactNode;
  trigger?: ReactNode;
  anchor?: ReactNode;
  color?: string;
} & PopoverProps &
  PopoverContentProps) => (
  <Root defaultOpen={defaultOpen} open={open} onOpenChange={onOpenChange}>
    <Trigger asChild>{trigger}</Trigger>
    {anchor && <Anchor>{anchor}</Anchor>}
    <Content
      sideOffset={5}
      side="top"
      {...contentProps}
      className={style.content}
      style={{ ['--help-tip-color' as any]: color }}
    >
      {children}
      <Arrow className={style.arrow} offset={12} />
      {onOpenChange && (
        <Close asChild className={style.close}>
          <span>
            <CloseIconButton />
          </span>
        </Close>
      )}
    </Content>
  </Root>
);
