import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export const MatchLink = ({
  children,
  to,
  className,
  activeClassName,
  active,
}: {
  children: ReactNode;
  to: string;
  className?: string;
  activeClassName?: string;
  active?: boolean;
}) => {
  return (
    <Link to={to} className={className + (active ? ' ' + activeClassName : '')}>
      {children}
    </Link>
  );
};
