import style from './Input.module.css';
import type { Props } from './Input.types';

export const Input = ({
  size = 'medium',
  error,
  fullWidth,
  ...rest
}: Props) => {
  return (
    <input
      {...rest}
      className={style[size] + ' ' + (error ? style.error : '')}
      style={fullWidth ? { width: '100%' } : undefined}
    />
  );
};
