export const LogoPolyflow = () => (
  <svg
    width="71"
    height="104"
    viewBox="0 0 71 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.804878C0 18.5858 14.4142 33 32.1951 33H70.1951C70.6396 33 71 32.6396 71 32.1951C71 14.4142 56.5858 0 38.8049 0H0.804879C0.360357 0 0 0.360355 0 0.804878Z"
      fill="#00D37F"
    />
    <path
      d="M0 68.1951C0 50.4142 14.4142 36 32.1951 36H70.1951C70.6396 36 71 36.3604 71 36.8049C71 54.5858 56.5858 69 38.8049 69H0.804879C0.360357 69 0 68.6396 0 68.1951Z"
      fill="#00D37F"
    />
    <path
      d="M0.999999 72C0.447714 72 -1.95703e-08 72.4477 -4.37114e-08 73L-1.36358e-06 103.195C-1.38301e-06 103.64 0.360355 104 0.804877 104L2.15663 104C19.1909 104 33 90.1909 33 73.1566C33 72.5178 32.4822 72 31.8434 72L0.999999 72Z"
      fill="#00D37F"
    />
  </svg>
);
