import { createContext, useContext, memo, useMemo } from 'react';
import { initializeApp } from 'firebase/app';

import type { ReactNode } from 'react';
import type { FirebaseApp } from 'firebase/app';

export const context = createContext(null as any as FirebaseApp);
export const useFirebase = () => useContext(context);

export const FirebaseProvider = memo<{ children: ReactNode }>(({ children }) => {
  const app = useMemo(() => initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE!)), []);

  return (
    <context.Provider value={app}>
      {children}
    </context.Provider>
  );
});
