import {
  Provider,
  Root,
  Trigger,
  Content,
  Arrow,
} from '@radix-ui/react-tooltip';

import type { ReactElement, ReactNode } from 'react';
import type {
  TooltipProps,
  TooltipContentProps,
} from '@radix-ui/react-tooltip';

import style from './Tooltip.module.css';

export const TooltipProvider = Provider;

export const Tooltip = ({
  children,
  text,
  defaultOpen,
  open,
  onOpenChange,
  delayDuration,
  arrowOffset,
  ...contentProps
}: {
  children: ReactNode;
  text: string | ReactElement;
  arrowOffset?: number;
} & TooltipProps &
  TooltipContentProps) => {
  return (
    <Root
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={onOpenChange}
      delayDuration={delayDuration}
    >
      <Trigger asChild>{children}</Trigger>
      <Content
        sideOffset={6}
        side="top"
        {...contentProps}
        className={style.content}
      >
        {text}
        <Arrow className={style.arrow} offset={arrowOffset} />
      </Content>
    </Root>
  );
};
