import { Fragment, useEffect, useRef } from 'react';
import {
  Root,
  Trigger,
  Value,
  Icon,
  Content,
  Viewport,
  Group,
  Item,
  ItemText,
  ItemIndicator,
  Label,
  Separator,
  ScrollUpButton,
  ScrollDownButton,
  SelectProps,
} from '@radix-ui/react-select';

import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from 'ui';
import style from './Select.module.css';

export type SelectItem = {
  value: string;
  label: string;
  disabled?: boolean;
  /**
   * For the rare situation that a button is needed.
   * 'value' prop will be still required but ignored.
   */
  action?: () => void;
};

type SelectItemsGroup = {
  name: string;
  items: SelectItem[];
};

function isItemsGroup(
  items: SelectItem[] | SelectItemsGroup[]
): items is SelectItemsGroup[] {
  return (items as SelectItemsGroup[])?.[0].name !== undefined;
}

export const Select = ({
  items,
  ariaLabel,
  size = 'medium',
  minWidth = 150,
  error,
  disableIndicator,
  required,
  ...props
}: {
  /**
   * Two formats are supported:
   * - Plain array of items.
   * - Arrays of items grouped.
   */
  items: SelectItem[] | SelectItemsGroup[];
  /**
   * Trigger aria label.
   */
  ariaLabel?: string;
  /**
   * Defaults to `medium`.
   */
  size?: 'small' | 'medium';
  minWidth?: number;
  error?: boolean;
  disableIndicator?: boolean;
  required?: boolean;
} & SelectProps) => {
  const container = useRef<HTMLDivElement>(null);

  // TEMP: fix for defaultValue when used uncontrolled.
  // Radix sets the first item as the select's value but it should be the defaultValue.
  useEffect(() => {
    const select = container.current?.querySelector('select');
    setTimeout(() => {
      if (select && props.defaultValue) {
        select.value = props.defaultValue;
      }
    }, 500);
  }, [props.defaultValue]);

  // If set to 'required' only pass validation with truthy values.
  useEffect(() => {
    const selectContainer = container.current;
    const select = container.current?.querySelector('select');

    if (selectContainer && select && required) {
      const listener = (event: Event) => {
        const value = (event.target as HTMLSelectElement)?.value;
        if (value) {
          select.setCustomValidity('');
        } else {
          select.setCustomValidity(' ');
        }
      };

      if (!props.defaultValue) {
        select.setCustomValidity(' ');
      }

      selectContainer.addEventListener('change', listener);

      return () => selectContainer.removeEventListener('change', listener);
    }
  }, [required, props.defaultValue]);

  return (
    <div ref={container}>
      <Root {...props}>
        <Trigger
          aria-label={ariaLabel}
          className={style[size] + ' ' + (error ? style.error : '')}
          style={{ minWidth }}
        >
          <Value />
          <Icon>
            <ChevronDownIcon />
          </Icon>
        </Trigger>
        <Content className={style.content}>
          <ScrollUpButton className={style.scrollButton}>
            <ChevronUpIcon />
          </ScrollUpButton>
          <Viewport className={style.viewport}>
            {items.length &&
              (isItemsGroup(items)
                ? items.map((groupItem, i) => (
                    <Fragment key={i}>
                      <Group>
                        <Label className={style.label}>{groupItem.name}</Label>
                        {groupItem.items.map((item) => (
                          <Item
                            key={item.value}
                            value={item.value}
                            className={style.item}
                            disabled={item.disabled}
                          >
                            <ItemText>{item.label}</ItemText>
                            {!disableIndicator && (
                              <ItemIndicator className={style.itemIndicator}>
                                <CheckIcon />
                              </ItemIndicator>
                            )}
                          </Item>
                        ))}
                      </Group>
                      {i !== items.length - 1 && (
                        <Separator className={style.separator} />
                      )}
                    </Fragment>
                  ))
                : items.map((item, i) =>
                    item.action ? (
                      <button
                        key={i}
                        onClick={item.action}
                        className={style.item + ' ' + style.action}
                      >
                        {item.label}
                      </button>
                    ) : (
                      <Item
                        key={item.value}
                        value={item.value}
                        className={style.item}
                        disabled={item.disabled}
                      >
                        <ItemText>{item.label}</ItemText>
                        {!disableIndicator && (
                          <ItemIndicator className={style.itemIndicator}>
                            <CheckIcon />
                          </ItemIndicator>
                        )}
                      </Item>
                    )
                  ))}
          </Viewport>
          <ScrollDownButton className={style.scrollButton}>
            <ChevronDownIcon />
          </ScrollDownButton>
        </Content>
      </Root>
    </div>
  );
};
