import { memo } from 'react';
import { Input } from '../Input';
import style from './PathInput.module.css';
import type { Props } from './PathInput.types';

export const PathInput = memo<Props>(({ onChange, locale, size, ...props }) => (
  <div className={style.root}>
    {locale && (
      <div
        className={style.locale + (size === 'small' ? ' ' + style.small : '')}
      >
        {'/' + locale}
      </div>
    )}
    <div className={style.input}>
      <Input
        {...props}
        size={size}
        fullWidth
        onChange={(event) => {
          event.target.value = `/${event.target.value
            .toLowerCase()
            .replace(/^\/*/, '')
            .replaceAll(/[^\w/-]/gi, '')}`;
          onChange?.(event);
        }}
      />
    </div>
  </div>
));
