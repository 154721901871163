import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { init, ErrorBoundary } from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';

import { Providers } from 'providers';
import App from './App';
import reportWebVitals from './reportWebVitals';

import type { ComponentProps, PropsWithChildren, FunctionComponent } from 'react';

// @todo https://github.com/getsentry/sentry-javascript/issues/4904
const SentryErrorBoundary = ErrorBoundary as any as FunctionComponent<PropsWithChildren<ComponentProps<typeof ErrorBoundary>>>;

if (process.env.NODE_ENV !== 'development') {
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 1.0,
  });
}
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <BrowserRouter>
      <SentryErrorBoundary
        fallback={({ error, componentStack, resetError }) => (
          <>
            <div>You have encountered an error</div>
            <div>{error.toString()}</div>
            <div>{componentStack}</div>
            <button onClick={() => resetError()}>Click here to reset!</button>
          </>
        )}
      >
        <Providers>
          <App />
        </Providers>
      </SentryErrorBoundary>
    </BrowserRouter>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
