import { memo, cloneElement, useId } from 'react';

import type { ReactElement } from 'react';

import style from './FormRow.module.css';

type Props = {
  children: ReactElement;
  id?: string;
  label?: string;
  error?: string;
};

export const FormRow = memo<Props>(function FormRow({
  children,
  id: propsId,
  label,
  error,
}) {
  const reactId = useId();
  const id = propsId || reactId;
  const invalid = !!error;
  const errorId = invalid ? `${id}--error` : undefined;
  const input = cloneElement(children, {
    id,
    'aria-errormessage': errorId,
    'aria-invalid': invalid,
  });

  return (
    <fieldset className={style.row}>
      {label && (
        <label className={style.label} htmlFor={id}>
          {label}
        </label>
      )}
      {input}
      {invalid && (
        <div className={style.error} id={errorId} role="alert">
          {error}
        </div>
      )}
    </fieldset>
  );
});
