import { memo } from 'react';

import type { Props } from './TranslationNodeText.types';

import style from './TranslationNodeText.module.css';

export const TranslationNodeText = memo<Props>(({ node }) => {
  return (
    <div
      className={style.node}
      dangerouslySetInnerHTML={{ __html: node.value }}
    />
  );
});
