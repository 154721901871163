import style from './Tag.module.css';

export const Tag = ({
  text,
  size,
  color = 'light',
}: {
  text: string;
  /**
   * Default is 'medium'
   */
  size?: 'small' | 'medium';
  color?: 'light' | 'pink';
}) => (
  <span
    className={
      (size === 'small' ? style.small : style.medium) + ' ' + style[color]
    }
  >
    {text}
  </span>
);
