import { ReactNode, useEffect, useState } from 'react';
import { Root, Trigger, Content, Arrow } from '@radix-ui/react-dropdown-menu';

import style from './DropdownMenu.module.css';

type Props = {
  trigger: ReactNode;
  content: ReactNode;
  offset?: number;
  noArrow?: boolean;
  /**
   * For uncontrolled set to undefined.
   */
  open?: boolean;
  openChange?: (open: boolean) => void;
};

export const DropdownContent = ({
  trigger,
  content,
  offset = 10,
  noArrow,
  open,
  openChange,
}: Props) => {
  const [openState, setOpenState] = useState<boolean>();

  useEffect(() => setOpenState(open), [open]);

  return (
    <div>
      <Root
        open={openState}
        onOpenChange={(open) => {
          setOpenState(undefined);
          openChange?.(open);
        }}
      >
        <Trigger asChild className={style.trigger}>
          {trigger}
        </Trigger>
        <Content sideOffset={offset} className={style.content}>
          {content}
          {!noArrow && <Arrow className={style.arrow} offset={12} />}
        </Content>
      </Root>
    </div>
  );
};
