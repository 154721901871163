import { forwardRef } from 'react';

import type { Props } from './AnchorButton.types';
import style from './Button.module.css';

export const AnchorButton = forwardRef<HTMLAnchorElement | null, Props>(
  (
    {
      text,
      icon: Icon,
      iconPosition,
      fullWidth,
      type,
      size = 'medium',
      variant = 'contained',
      look = 'standard',
      ...rest
    },
    ref
  ) => {
    if (Icon && !text) {
      return (
        <a
          {...rest}
          ref={ref}
          className={`${style[look]} ${style.icon} ${style[variant]} ${style[size]}`}
        >
          <i>
            <Icon />
          </i>
        </a>
      );
    }

    return (
      <a
        {...rest}
        ref={ref}
        className={`${style[look]} ${style[variant]} ${style[size]}`}
        style={Object.assign(
          { width: fullWidth ? '100%' : 'fit-content' },
          rest.style
        )}
      >
        {(!iconPosition || iconPosition === 'left') && Icon && (
          <i className={style.startIcon}>
            <Icon />
          </i>
        )}
        <span>{text}</span>
        {iconPosition === 'right' && Icon && (
          <i className={style.endIcon}>
            <Icon />
          </i>
        )}
      </a>
    );
  }
);
