import {
  getAuth,
  onIdTokenChanged,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  signOut,
  User as FirebaseUser,
} from 'firebase/auth';
import { onlyResolvesLast } from 'awesome-only-resolves-last-promise';
import { identifyUserForTracking, trackLogin } from 'datalayer';

import type { Credentials, AuthState } from './types';
import type { HTTPClient } from './client';

export const initAuth = (client: HTTPClient) => {
  const auth = getAuth(client.firebase);
  const emitter = new EventTarget();
  const getUser = onlyResolvesLast(() =>
    client.get<AuthState['user']>('v1/user')
  );
  let state: AuthState = { firebaseUser: auth.currentUser };

  const updateAuthState = async ({
    user,
    token,
  }: {
    user?: AuthState['user'];
    token?: string;
  } = {}) => {
    try {
      if (!state.firebaseUser && auth.currentUser) {
        trackLogin({
          uid: auth.currentUser.uid,
          email: auth.currentUser.email ?? 'unknown',
        });
      }

      state = {
        firebaseUser: auth.currentUser,
        user: user || (await getUser()),
      };

      emitter.dispatchEvent(new CustomEvent('auth'));

      if (state.user) {
        identifyUserForTracking(state.user.id);
      }
    } catch (error: any) {
      state = {
        firebaseUser: auth.currentUser,
        user: null,
        needsMigration: error?.status === 423,
      };
      emitter.dispatchEvent(new CustomEvent('auth'));
    }
  };

  onIdTokenChanged(auth, (user: FirebaseUser | null) =>
    updateAuthState({
      token: (user as FirebaseUser & { accessToken: string })?.accessToken,
    })
  );

  return {
    updateAuthState,
    onAuthChanged: (cb: (result: AuthState) => void) => {
      const listener = () => cb(state);
      emitter.addEventListener('auth', listener);
      return () => emitter.removeEventListener('auth', listener);
    },
    signUpWithEmailAndPassword: async ({ email, password }: Credentials) => {
      await createUserWithEmailAndPassword(auth, email, password);
    },
    signInWithEmailAndPassword: async function ({
      email,
      password,
    }: Credentials) {
      await signInWithEmailAndPassword(auth, email, password);
    },
    continueWithGoogle: () => {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      return signInWithPopup(auth, provider).then((credentials) => {
        return credentials;
      });
    },
    signOut: () => {
      return signOut(auth);
    },
    sendRecoverPasswordEmail: async (email: string) => {
      await client.post('v1/user/password', { email });
    },
    changePassword: async function (token: string, password: string) {
      const { email } = await client.put<{ email: string }>(
        'v1/user/password',
        { token, password }
      );
      await this.signInWithEmailAndPassword({ email, password });
    },
    registerExtension: async function (sessionId: string) {
      await client.post('v1/user/extension', { sessionId });
    },
  };
};
