import { Popover, CloseIconButton } from 'ui';
import { useProjectOnBoarding } from 'partials';

import type { ReactNode } from 'react';

import style from './TutorialTip.module.css';

export const TutorialTip = ({
  children,
  step,
  text,
  link,
  end,
  side = 'bottom',
  action,
  backAction,
}: {
  children: ReactNode;
  step: number;
  text: string;
  link?: {
    href: string;
    text?: string;
  };
  end?: boolean;
  side?: 'bottom' | 'top' | 'right' | 'left';
  /**
   * An optional effect executed when the user clicks on the next button.
   */
  action?: () => void;
  /**
   * An optional effect executed when the user clicks on the back button.
   */
  backAction?: () => void;
}) => {
  const { onBoardingStep, setOnBoardingStep, skipProjectOnBoarding } =
    useProjectOnBoarding();

  if (onBoardingStep !== undefined) {
    return (
      <Popover
        open={onBoardingStep === step}
        color="rgba(44, 97, 243, 1)"
        side={side}
        anchor={children}
      >
        <CloseIconButton
          onClick={skipProjectOnBoarding}
          rootClassName={style.skip}
        />
        <p className={style.text}>{text}</p>
        {link && (
          <a
            href={link.href}
            className={style.text}
            target="_blank"
            rel="noreferrer"
          >
            {link.text ?? link.href}
          </a>
        )}
        <div
          className={
            style.buttons + ' ' + style[onBoardingStep > 1 ? 'spaced' : 'end']
          }
        >
          {onBoardingStep > 1 && (
            <button
              onClick={() => {
                backAction?.();
                setOnBoardingStep(onBoardingStep - 1);
              }}
            >
              Back
            </button>
          )}
          <button
            autoFocus
            onClick={() => {
              action?.();
              end && skipProjectOnBoarding();
              setOnBoardingStep(end ? undefined : onBoardingStep + 1);
            }}
          >
            Got it{end ? '' : ', next'}
          </button>
        </div>
      </Popover>
    );
  } else {
    return <>{children}</>;
  }
};
