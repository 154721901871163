export const LogoPolyflowCircle = ({ width }: { width?: number }) => {
  return (
    <svg
      width={width ?? '100'}
      viewBox="100 64 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="126" cy="90" r="26" fill="#00D37F" />
      <path
        d="M118.759 77.1906C118.759 81.4932 122.247 84.9812 126.549 84.9812H135.835C135.942 84.9812 136.029 84.894 136.029 84.7865C136.029 80.4838 132.542 76.9958 128.239 76.9958H118.954C118.846 76.9958 118.759 77.083 118.759 77.1906Z"
        fill="white"
      />
      <path
        d="M118.573 93.7307C118.573 89.3472 122.126 85.7937 126.51 85.7937H135.878C135.987 85.7937 136.076 85.8826 136.076 85.9921C136.076 90.3756 132.523 93.9291 128.139 93.9291H118.771C118.662 93.9291 118.573 93.8403 118.573 93.7307Z"
        fill="white"
      />
      <path
        d="M126.935 95.0283C126.935 99.431 123.365 103 118.963 103L118.782 103C118.669 103 118.578 102.909 118.578 102.796L118.578 96.829C118.578 95.7244 119.473 94.829 120.578 94.829L126.735 94.829C126.845 94.829 126.935 94.9182 126.935 95.0283Z"
        fill="white"
      />
    </svg>
  );
};
