import { memo } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import type { Props } from './TranslationAlternativeRichText.types';

import style from './TranslationAlternativeRichText.module.css';

const externalUrl = /^https?:\/\//;

// Custom Quill anchor element: https://github.com/quilljs/quill/issues/1139

const Link = Quill.import('formats/link');

class CustomLink extends Link {
  // Each time an anchor element is created.
  static create(value: string) {
    const node: HTMLAnchorElement = super.create(value);
    value = this.sanitize(value);

    // if (validations.isNumeric(value)) {
    //   node.setAttribute('href', 'tel:' + value);
    // }

    // if (validations.isEmail(value)) {
    //   node.setAttribute('href', 'mailto:' + value);
    // }

    if (externalUrl.test(value)) {
      // By default Quill adds the 'target' and 'rel' to all anchor elements.
    } else {
      node.removeAttribute('target');
      node.removeAttribute('rel');
    }

    return node;
  }

  // Each time an anchor element is edited.
  format(name: 'link', value: string) {
    super.format(name, value);

    if (name !== this.statics.blotName || !value) {
      return;
    }

    if (externalUrl.test(value)) {
      this.domNode.setAttribute('target', '_blank');
      this.domNode.setAttribute('rel', 'noopener noreferrer');
    } else {
      this.domNode.removeAttribute('target');
      this.domNode.removeAttribute('rel');
    }
  }
}

Quill.register(CustomLink);

// @todo ReactQuill is adding empty list items without reason
export const TranslationAlternativeRichText = memo<Props>(
  ({ alternative, setAlternative }) => {
    return (
      <div className={style.node}>
        <ReactQuill
          theme="snow"
          value={alternative.replaceAll('<li></li>', '').replaceAll('<li><br></li>', '')}
          onChange={content => {
            const clean = content.replaceAll('<li></li>', '').replaceAll('<li><br></li>', '');
            if (clean !== alternative) {
              setAlternative(clean);
            }
          }}
        />
      </div>
    );
  }
);
