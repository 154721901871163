import type { ReactNode } from 'react';
import {
  Root,
  Sub,
  Trigger,
  Content,
  SubContent,
  Item,
  SubTrigger,
  Separator,
  CheckboxItem,
  ItemIndicator,
  Label,
  RadioGroup,
  RadioItem,
  Arrow,
} from '@radix-ui/react-dropdown-menu';

import { CheckIcon, ChevronRightIcon, DotIcon } from '../';

import style from './DropdownMenu.module.css';

function renderMenuContents(contents: MenuContents): ReactNode {
  return contents.map((item, i) => {
    switch (item.type) {
      case 'separator':
        return <Separator key={i} className={style.separator} />;
      case 'label':
        return (
          <Label key={i} className={style.label}>
            {item.text}
          </Label>
        );
      case 'checkbox':
        return (
          <CheckboxItem
            key={i}
            className={style.checkboxItem}
            onCheckedChange={item.onCheckedChange}
            checked={item.checked}
            disabled={item.disabled}
          >
            <ItemIndicator className={style.itemIndicator}>
              <CheckIcon />
            </ItemIndicator>
            {item.text}
          </CheckboxItem>
        );
      case 'radioGroup':
        return (
          <RadioGroup
            key={i}
            value={item.radioValue}
            onValueChange={item.onRadioValueChange}
          >
            {item.radioItems?.map((radioItem, j) => (
              <RadioItem
                key={j}
                value={radioItem.value}
                className={style.radioItem}
                disabled={item.disabled}
              >
                <ItemIndicator className={style.itemIndicator}>
                  <DotIcon />
                </ItemIndicator>
                {radioItem.text}
              </RadioItem>
            ))}
          </RadioGroup>
        );
      case 'submenu':
        return (
          <Sub key={i}>
            <SubTrigger className={style.triggerItem}>
              {item.text}
              <div className={style.rightSlot}>
                <ChevronRightIcon />
              </div>
            </SubTrigger>
            <SubContent
              sideOffset={2}
              alignOffset={-5}
              className={style.content}
            >
              {item.children && renderMenuContents(item.children)}
            </SubContent>
          </Sub>
        );
    }
    return (
      <Item
        key={i}
        className={
          style.item + (item.customClassName ? ' ' + item.customClassName : '')
        }
        onClick={item.disabled ? undefined : item.onClick}
        disabled={item.disabled}
      >
        {item.text}
        {item.rightSlot && (
          <div className={style.rightSlot}>{item.rightSlot}</div>
        )}
      </Item>
    );
  });
}

export type MenuContents = {
  /**
   * Default type is 'button'.
   */
  type?:
    | 'button'
    | 'label'
    | 'separator'
    | 'checkbox'
    | 'radioGroup'
    | 'submenu';
  /**
   * Required for all except for 'separator' and 'radioGroup' types.
   */
  text?: string;
  /**
   * Required when 'type' is 'button'.
   */
  onClick?: () => void;
  /**
   * Required when 'type' is 'checkbox'.
   */
  onCheckedChange?: (checked: boolean) => void;
  /**
   * Required when 'type' is 'checkbox'.
   */
  checked?: boolean;
  /**
   * Required when 'type' is 'radioGroup'.
   */
  onRadioValueChange?: (value: string) => void;
  /**
   * Required when 'type' is 'radioGroup'.
   */
  radioValue?: string;
  /**
   * Required when 'type' is 'radioGroup'.
   */
  radioItems?: {
    text: string;
    value: string;
  }[];
  /**
   * Secondary text or icon to display to the right of the main text.
   */
  rightSlot?: string | JSX.Element;
  /**
   * Required when 'type' is 'submenu'.
   */
  children?: MenuContents;
  disabled?: boolean;
  customClassName?: string;
}[];

type Props = {
  trigger: ReactNode;
  contents: MenuContents;
  offset?: number;
  noArrow?: boolean;
};

export const DropdownMenu = ({
  trigger,
  contents,
  offset = 10,
  noArrow,
}: Props) => {
  return (
    <div>
      <Root>
        <Trigger asChild className={style.trigger}>
          {trigger}
        </Trigger>
        <Content
          sideOffset={offset}
          className={style.content + ' ' + style.padding}
        >
          {renderMenuContents(contents)}
          {!noArrow && <Arrow className={style.arrow} offset={12} />}
        </Content>
      </Root>
    </div>
  );
};
