import type { ReactNode } from 'react';
import {
  Root,
  Trigger,
  Portal,
  Overlay,
  Content as RawContent,
  Title,
  Description,
  Cancel,
  Action,
  AlertDialogProps,
} from '@radix-ui/react-alert-dialog';

import { Button } from 'ui';
import style from './Dialog.module.css';

function Content({ children, ...props }: { children: ReactNode }) {
  return (
    <Portal>
      <Overlay className={style.overlay} />
      <RawContent
        {...props}
        className={style.content + ' ' + style.contentPadding}
      >
        {children}
      </RawContent>
    </Portal>
  );
}

export const AlertDialog = ({
  trigger,
  title,
  description,
  confirmLabel = 'Confirm',
  onConfirm,
  onCancel,
  processingConfirm,
  ...props
}: {
  /**
   * The element to trigger the dialog.
   */
  trigger?: ReactNode;
  title: string;
  description: string;
  confirmLabel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  processingConfirm?: boolean;
} & AlertDialogProps) => (
  <Root {...props}>
    {trigger && <Trigger asChild>{trigger}</Trigger>}
    <Content>
      <Title className={style.title}>{title}</Title>
      <Description className={style.description}>{description}</Description>
      <div className={style.actions}>
        <Cancel asChild>
          <Button text="Cancel" onClick={onCancel} />
        </Cancel>
        <Action asChild>
          <Button
            look="danger"
            text={confirmLabel}
            onClick={onConfirm}
            progress={processingConfirm}
            disabled={processingConfirm}
          />
        </Action>
      </div>
    </Content>
  </Root>
);
