import { memo } from 'react';
import { useInView } from 'react-intersection-observer';
import type { ItemProps, Props } from './VirtualList.types';

const TypedVirtualItem = <Datum extends unknown>({
  index,
  datum,
  component: Component,
  placeholder: Placeholder,
}: ItemProps<Datum>) => {
  const { ref, inView } = useInView({ threshold: 0 });

  return (
    <li ref={ref}>
      {inView ? <Component index={index} node={datum} /> : <Placeholder />}
    </li>
  );
};

const VirtualItem = memo(TypedVirtualItem) as typeof TypedVirtualItem;

const TypedVirtualList = <Datum extends unknown>({
  className,
  data,
  component,
  placeholder,
}: Props<Datum>) => {
  return (
    <ul className={className}>
      {data.map((datum, index) => (
        <VirtualItem
          key={index}
          index={index}
          datum={datum}
          component={component}
          placeholder={placeholder}
        />
      ))}
    </ul>
  );
};

export const VirtualList = memo(TypedVirtualList) as typeof TypedVirtualList;
