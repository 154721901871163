export const LogoPolyflowText = () => (
  <svg
    width="179"
    height="48"
    viewBox="0 0 179 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.978 39.6619H51.1978V30.7036C52.1662 32.0525 54.034 32.8826 56.1438 32.8826C61.2629 32.8826 64.5487 29.2163 64.5487 23.8898C64.5487 18.5632 61.4704 14.8969 56.4551 14.8969C54.1377 14.8969 52.2354 15.8653 51.1978 17.3526V15.1736H46.978V39.6619ZM55.6596 29.2163C52.7888 29.2163 50.9211 27.1064 50.9211 23.8898C50.9211 20.6731 52.7888 18.5632 55.6596 18.5632C58.4612 18.5632 60.329 20.6731 60.329 23.8898C60.329 27.1064 58.4612 29.2163 55.6596 29.2163Z"
      fill="#1A1F22"
    />
    <path
      d="M75.605 32.8826C80.897 32.8826 84.7017 29.078 84.7017 23.8898C84.7017 18.7016 80.897 14.8969 75.605 14.8969C70.2785 14.8969 66.4738 18.7016 66.4738 23.8898C66.4738 29.078 70.2785 32.8826 75.605 32.8826ZM75.605 29.2163C72.6996 29.2163 70.7627 27.0373 70.7627 23.8898C70.7627 20.7423 72.6996 18.5632 75.605 18.5632C78.4758 18.5632 80.4127 20.7423 80.4127 23.8898C80.4127 27.0373 78.4758 29.2163 75.605 29.2163Z"
      fill="#1A1F22"
    />
    <path
      d="M87.8148 32.6059H92.0346V7.70258H87.8148V32.6059Z"
      fill="#1A1F22"
    />
    <path
      d="M111.2 15.1736H106.841L102.829 27.141L98.7825 15.1736H94.459L100.823 32.6059L98.3674 39.6619H102.414L105.112 31.7758L111.2 15.1736Z"
      fill="#1A1F22"
    />
    <path
      d="M127.041 7.70258V15.1736H119.777V13.7555C119.777 12.2336 120.746 11.2997 122.233 11.2997H124.862V7.70258H121.749C117.979 7.70258 115.523 10.02 115.523 13.548V15.1736H112.099V18.7361H115.523V32.6059H119.777V18.7361H127.041V32.6059H131.261V7.70258H127.041Z"
      fill="#1A1F22"
    />
    <path
      d="M143.497 32.8826C148.789 32.8826 152.594 29.078 152.594 23.8898C152.594 18.7016 148.789 14.8969 143.497 14.8969C138.171 14.8969 134.366 18.7016 134.366 23.8898C134.366 29.078 138.171 32.8826 143.497 32.8826ZM143.497 29.2163C140.592 29.2163 138.655 27.0373 138.655 23.8898C138.655 20.7423 140.592 18.5632 143.497 18.5632C146.368 18.5632 148.305 20.7423 148.305 23.8898C148.305 27.0373 146.368 29.2163 143.497 29.2163Z"
      fill="#1A1F22"
    />
    <path
      d="M158.939 32.6059H162.675L166.203 20.8806L169.696 32.6059H173.466L179 15.1736H174.781L171.564 26.8297L168.14 15.1736H164.231L160.807 26.8297L157.625 15.1736H153.371L158.939 32.6059Z"
      fill="#1A1F22"
    />
    <path
      d="M0.339844 0.35624C0.339844 8.2255 6.71914 14.6048 14.5884 14.6048H31.5707C31.7674 14.6048 31.9269 14.4453 31.9269 14.2486C31.9269 6.37932 25.5476 2.57492e-05 17.6784 2.57492e-05H0.696059C0.499328 2.57492e-05 0.339844 0.159509 0.339844 0.35624Z"
      fill="#00D37F"
    />
    <path
      d="M0.000244141 30.6086C0.000244141 22.5915 6.49939 16.0923 14.5165 16.0923H31.6501C31.8505 16.0923 32.013 16.2548 32.013 16.4553C32.013 24.4724 25.5138 30.9715 17.4967 30.9715H0.363151C0.162723 30.9715 0.000244141 30.809 0.000244141 30.6086Z"
      fill="#00D37F"
    />
    <path
      d="M15.2842 32.9705C15.2842 41.0228 8.75652 47.5504 0.704255 47.5504L0.372899 47.5504C0.167018 47.5504 0.000117287 47.3835 0.000117269 47.1776L0.00011617 34.606C0.000116074 33.5014 0.895547 32.606 2.00012 32.606L14.9197 32.606C15.121 32.606 15.2842 32.7692 15.2842 32.9705Z"
      fill="#00D37F"
    />
  </svg>
);
