import { memo, useState, useRef, useCallback, useEffect } from 'react';

import { CircularProgress, CheckIcon } from 'ui';
import { TranslationNodeText } from './TranslationNodeText';
import { TranslationNodeRichText } from './TranslationNodeRichText';
import { TranslationAlternativeText } from './TranslationAlternativeText';
import { TranslationAlternativeRichText } from './TranslationAlternativeRichText';
import { useTranslations } from './context';

import style from './Translation.module.css';

type Props = {
  node: {
    id: string;
    value: string;
    type: 'text' | 'rich';
    cmsFieldName?: string;
  };
};

export const TranslationRowText = memo<Props>(({ node }) => {
  const { id, type } = node;
  const { alternatives, setNode, removeNode } = useTranslations();
  const [state, setState] =
    useState<{ saving?: boolean; value: string } | null>(null);
  const [added, setAdded] = useState(false);
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    setState(alternatives[id] ?? null);
  }, [alternatives, id]);

  const addAlternative = useCallback(async () => {
    setAdding(true);
    setState({ saving: true, value: '' });
    await setNode(id, '');
    setState({ saving: false, value: '' });
    setAdding(false);
    setAdded(true);
    if (type === 'rich') root.current?.focus();
  }, [id, setNode, type]);

  const removeAlternative = useCallback(() => {
    setAdded(false);
    setState(null);
    removeNode(id);
    setActive(false);
  }, [id, removeNode]);

  const nodeAlernativeId = alternatives[id]?.id;

  const setAlternative = useCallback(
    (data: string) => {
      setAdded(false);
      setState({ saving: true, value: data });
      setNode(id, data, nodeAlernativeId);
    },
    [id, setNode, nodeAlernativeId]
  );

  const [active, setActive] = useState(false);

  const root = useRef<HTMLDivElement>(null);
  const deleteButtonRef = useRef<HTMLButtonElement>(null);
  const addButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <div>
      <div className={style.nodeData}>
        <span>{node.cmsFieldName ? node.cmsFieldName : null}</span>
        <span>
          {type === 'rich'
            ? 'Type: Rich Text'
            : type === 'text'
            ? 'Type: Text'
            : null}
        </span>
      </div>
      <div
        ref={root}
        className={style.translation}
        tabIndex={-1}
        onFocus={(e) => {
          if (
            (e.target as any) === deleteButtonRef.current ||
            (e.target as any) === addButtonRef.current
          ) {
            return;
          }
          setActive(true);
        }}
        onBlur={(e) => {
          if (root.current?.contains(e.relatedTarget)) {
            return;
          }
          // Delay required to allow click events in the blur element to run before this, otherwise only this runs.
          setTimeout(() => setActive(false), 150);
        }}
      >
        <div
          className={style.original}
          style={{ maxHeight: active ? 'unset' : undefined }}
        >
          {type === 'text' ? (
            <TranslationNodeText node={node} />
          ) : type === 'rich' ? (
            <TranslationNodeRichText node={node} />
          ) : null}
        </div>
        <div
          className={style.alternative}
          style={{ maxHeight: active ? 'unset' : undefined }}
        >
          <div
            className={
              /* state?.isValid ?? */ true ? style.input : style.invalidInput
            }
          >
            {adding ? (
              <div className={style.adding}>
                <CircularProgress />
              </div>
            ) : state === null ? (
              <button
                type="button"
                className={style.adder}
                onClick={addAlternative}
                ref={addButtonRef}
              >
                <div>Add translation</div>
              </button>
            ) : type === 'text' ? (
              <TranslationAlternativeText
                shouldFocus={added}
                alternative={state.value}
                setAlternative={setAlternative}
              />
            ) : type === 'rich' ? (
              <TranslationAlternativeRichText
                shouldFocus={added}
                alternative={state.value}
                setAlternative={setAlternative}
              />
            ) : null}
          </div>
          <div className={style.actions}>
            {state !== null &&
              (state.saving ? (
                <span className={style.saving}>
                  <CircularProgress />
                </span>
              ) : (
                <CheckIcon />
              ))}
            {state !== null && (
              <button
                tabIndex={-1}
                type="button"
                className={style.deleteAction}
                onClick={removeAlternative}
                ref={deleteButtonRef}
              >
                Delete
              </button>
            )}
            {/* {state && !state.isValid && (
              <button
                tabIndex={-1}
                type="button"
                className={style.validateAction}
                onClick={() => setAlternative({ ...state, isValid: true })}
              >
                Validate
              </button>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
});
