import { Tag } from '../Tag';
import { DropdownMenu } from '../DropdownMenu';
import { OptionsIcon } from '../Icon';
import type { Props } from './Card.types';
import style from './Card.module.css';

export function Card({
  children,
  padding = 'l',
  title,
  className,
  options,
}: Props) {
  return (
    <div className={style[padding] + ' ' + className}>
      {(title || options) && (
        <div
          className={style.header}
          style={{
            justifyContent:
              title && options
                ? 'space-between'
                : !title && options
                ? 'flex-end'
                : 'unset',
          }}
        >
          {title && <Tag text={title} />}
          {options && (
            <DropdownMenu
              trigger={
                <button type="button" className={style.options}>
                  <OptionsIcon />
                </button>
              }
              contents={options}
            />
          )}
        </div>
      )}
      {children}
    </div>
  );
}
