import { Children, cloneElement } from 'react';

import type { ReactChild, ReactNode } from 'react';

import style from './SimpleTooltip.module.css';

export const SimpleTooltip = ({
  children,
  text,
  align = 'top',
  width = 160,
  offset = 10,
}: {
  children: ReactChild;
  text: string | ReactNode;
  /**
   * By default it is positioned top.
   */
  align?: 'top' | 'top-left' | 'bottom';
  width?: number;
  offset?: number;
}): JSX.Element => {
  return Children.map(children, (child) => {
    if (typeof child !== 'string' && typeof child !== 'number') {
      return cloneElement(child, {
        className: `${child.props.className} ${style.tooltipWrapper}`,
        children: [
          child.props.children,
          <span
            className={style.tooltip + ' ' + style[align]}
            style={
              {
                '--tooltip-width': width + 'px',
                '--tooltip-offset': offset + 'px',
              } as any
            }
            key="tooltip"
          >
            {text}
          </span>,
        ],
      });
    }
  })[0];
};
