import {
  createContext,
  useContext,
  memo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import cn from 'classnames';
import { useApi } from 'providers';
import { Button, FileInput, Dialog, Spinner } from 'ui';
import style from './ImageGallery.module.css';

import type { Image } from 'providers/api/types';
import type { Context, Props, PickCallback } from './ImageGallery.types';

export const context = createContext(null as any as Context);
export const useImageGallery = () => useContext(context);

export const ImageGalleryProvider = memo<Props>(({ projectId, children }) => {
  const api = useApi();
  const [images, setImages] = useState<Image[] | null>(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [selected, setSelected] = useState<Image | null>(null);
  const [pickCallback, setPickCallback] = useState<PickCallback | null>(null);

  const openImageGallery = useCallback((cb: PickCallback) => {
    setPickCallback(() => cb);
  }, []);

  const close = useCallback(() => {
    setPickCallback(null);
    setSelected(null);
  }, []);

  const confirm = useCallback(() => {
    pickCallback?.(selected);
    close();
  }, [pickCallback, selected, close]);

  const uploadImage = useCallback(
    (file: File) => {
      setUploadingImage(true);
      api.projects
        .uploadImage(projectId, file)
        .then((uploaded) =>
          setImages((images) => [uploaded, ...(images ?? [])])
        )
        .catch((error) => alert(error.message))
        .finally(() => setUploadingImage(false));
    },
    [api, projectId]
  );

  const removeImage = useCallback(
    (imageId: string) => {
      setImages(
        (images) => images?.filter((image) => image.id !== imageId) ?? []
      );
      api.projects
        .removeImage(projectId, imageId)
        .catch((error) => alert(error.message));
    },
    [api, projectId]
  );

  useEffect(() => {
    api.projects
      .listImages(projectId)
      .then(setImages)
      .catch((error) => alert(error.message));
  }, [projectId, api]);

  return (
    <context.Provider value={{ openImageGallery }}>
      {children}
      <Dialog
        size="xxl"
        title="Insert media"
        description="Select the image:"
        open={!!pickCallback}
        onOpenChange={close}
      >
        <ul className={style.items}>
          <li className={style.item}>
            <label
              className={cn(style.adder, uploadingImage && style.disabled)}
            >
              <FileInput
                onFile={uploadImage}
                accept="image/*"
                disabled={uploadingImage}
              />
              <div
                className={cn(
                  style.placeholder,
                  !uploadingImage && style.disabled
                )}
              >
                Upload image
              </div>
            </label>
          </li>
          {uploadingImage && (
            <li className={style.item}>
              <span className={style.spinner}>
                <Spinner />
              </span>
            </li>
          )}
          {images?.map((image) => (
            <li key={image.id} className={style.item}>
              <div className={style.actions}>
                <button
                  type="button"
                  className={style.delete}
                  onClick={() => {
                    removeImage(image.id);
                    if (image.id === selected?.id) {
                      setSelected(null);
                    }
                  }}
                >
                  Delete
                </button>
                {selected?.id === image.id && (
                  <div className={style.check}>Selected</div>
                )}
              </div>
              <button
                type="button"
                className={style.preview}
                onClick={() => {
                  setSelected(image.id === selected?.id ? null : image);
                }}
              >
                <img alt={image.id} src={image.url} />
              </button>
            </li>
          ))}
        </ul>
        <div className={style.buttons}>
          <Button text="Cancel" onClick={close} />
          <Button
            look="dark"
            text="Confirm selection"
            disabled={!selected}
            onClick={confirm}
          />
        </div>
      </Dialog>
    </context.Provider>
  );
});
