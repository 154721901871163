import { memo, useState, useEffect, useRef, useCallback } from 'react';

import { CircularProgress, CheckIcon } from 'ui';
import { TranslationNodeImage } from './TranslationNodeImage';
import { TranslationAlternativeImage } from './TranslationAlternativeImage';
import { useTranslations } from './context';

import style from './Translation.module.css';

type Props = {
  node: { id: string; value: string; cmsFieldName?: string };
};

export const TranslationRowImage = memo<Props>(({ node }) => {
  const { id } = node;
  const { alternatives, setNode, removeNode } = useTranslations();
  const [state, setState] =
    useState<{ saving?: boolean; value: string } | null>(null);
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    setState(alternatives[id] ?? null);
  }, [alternatives, id]);

  const addAlternative = useCallback(async () => {
    setAdding(true);
    setState({ saving: true, value: '' });
    await setNode(id, '');
    setState({ saving: false, value: '' });
    setAdding(false);
    root.current?.focus();
  }, [id, setNode]);

  const removeAlternative = useCallback(() => {
    setState(null);
    removeNode(id);
    setActive(false);
  }, [id, removeNode]);

  const nodeAlernativeId = alternatives[id]?.id;

  const setAlternative = useCallback(
    (data: string) => {
      setState({ saving: true, value: data });
      setNode(id, data, nodeAlernativeId);
    },
    [id, setNode, nodeAlernativeId]
  );

  const [active, setActive] = useState(false);

  const root = useRef<HTMLDivElement>(null);
  const deleteButtonRef = useRef<HTMLButtonElement>(null);
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const translationActionsRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <div className={style.nodeData}>
        {node.cmsFieldName ? <span>{node.cmsFieldName}</span> : null}
      </div>
      <div
        ref={root}
        className={style.translation}
        tabIndex={-1}
        onFocus={(e) => {
          if (
            (e.target as any) === deleteButtonRef.current ||
            (e.target as any) === addButtonRef.current ||
            translationActionsRef.current?.contains(e.target)
          ) {
            return;
          }
          setActive(true);
        }}
        onBlur={(e) => {
          if (root.current?.contains(e.relatedTarget)) {
            return;
          }
          // Delay required to allow click events in the blur element to run before this, otherwise only this runs.
          setTimeout(() => setActive(false), 150);
        }}
      >
        <div
          className={style.original}
          style={{ maxHeight: active ? 'unset' : undefined }}
        >
          <TranslationNodeImage node={node} />
        </div>
        <div
          className={style.alternative}
          style={{ maxHeight: active ? 'unset' : undefined }}
        >
          <div
            className={
              /* state?.isValid ?? */ true ? style.input : style.invalidInput
            }
          >
            {adding ? (
              <div className={style.adding}>
                <CircularProgress />
              </div>
            ) : state === null ? (
              <button
                type="button"
                className={style.adder}
                onClick={addAlternative}
                ref={addButtonRef}
              >
                <div>Add translation</div>
              </button>
            ) : (
              <TranslationAlternativeImage
                // shouldFocus={added} // TODO add this focus
                alternative={state.value}
                setAlternative={setAlternative}
                ref={translationActionsRef}
              />
            )}
          </div>
          <div className={style.actions}>
            {state !== null &&
              (state.saving ? (
                <span className={style.saving}>
                  <CircularProgress />
                </span>
              ) : (
                <CheckIcon />
              ))}
            {state !== null && (
              <button
                tabIndex={-1}
                type="button"
                className={style.deleteAction}
                onClick={removeAlternative}
                ref={deleteButtonRef}
              >
                Delete
              </button>
            )}
            {/* {state && !state.isValid && (
              <button
                tabIndex={-1}
                type="button"
                className={style.validateAction}
                onClick={() => setAlternative({ ...state, isValid: true })}
              >
                Validate
              </button>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
});
