import { Link as RouterLink } from 'react-router-dom';

import style from './style.module.css';

/**
 * Link with style.
 * By default acts as external link.
 */
export const Link = ({
  text,
  url,
  internal,
}: {
  text: string;
  url: string;
  internal?: boolean;
}) => {
  return internal ? (
    <RouterLink className={style.root} to={url}>
      {text}
    </RouterLink>
  ) : (
    <a className={style.root} href={url} target="_blank" rel="noreferrer">
      {text}
    </a>
  );
};
