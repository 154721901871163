import { createContext, memo, useContext, useRef } from 'react';
import { useFirebase } from '../firebase';
import { HTTPClient } from './client';
import { initAuth } from './auth';
import { initUser } from './user';
import { initProjects } from './projects';
import { initDomains } from './domains';
import { initSubscriptions } from './subscriptions';

import type { ReactNode } from 'react';
import type { FirebaseApp } from 'firebase/app';

type App = ReturnType<typeof initApp>;

function initApp(firebase: FirebaseApp) {
  const client = new HTTPClient(firebase, process.env.REACT_APP_API!);
  const auth = initAuth(client);

  return {
    auth,
    user: initUser(client, auth.updateAuthState),
    projects: initProjects(client),
    domains: initDomains(client),
    subscriptions: initSubscriptions(client),
  };
}

const context = createContext(null as any as App);
export const useApi = () => useContext(context);

export const ApiProvider = memo<{ children: ReactNode }>(function ApiProvider({
  children,
}) {
  const firebase = useFirebase();
  const api = useRef<App | null>(null);

  if (!api.current) {
    api.current = initApp(firebase);
  }

  return <context.Provider value={api.current}>{children}</context.Provider>;
});
