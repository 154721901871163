import { useState, useCallback, memo } from 'react';

import { DropdownMenu, Button } from 'ui';
import { getLangName } from 'iso_639-1_codes';
// import { NewLangDialog } from './NewLangDialog';

import type { Props } from './LanguageSelector.types';
import type { MenuContents } from 'ui/DropdownMenu/DropdownMenu';
import type { ArrayElement } from 'types';

import style from './LanguageSelector.module.css';

export const LanguageSelector = memo(
  ({ languages, disabled, trigger, initialValue, onPick }: Props) => {
    const [picked, setPicked] = useState(initialValue);
    // const [openNewLang, setOpenNewLang] = useState(false);

    const onPickLang = useCallback(
      (lang: string) => {
        setPicked(lang);
        onPick(lang);
      },
      [onPick]
    );

    const menuContents = languages.map(
      (lang) =>
        ({
          type: 'button',
          text: getLangName(lang) + ' (' + lang + ')',
          onClick: () => onPickLang(lang),
          disabled: disabled ? disabled.includes(lang) : false,
        } as ArrayElement<MenuContents>)
    );
    /* .concat(
        { type: 'separator' },
        {
          type: 'button',
          text: '+ Add a new language',
          customClassName: style.newLangButton,
          onClick: () => setOpenNewLang(true),
        }
      ) */

    // const closeLangDialog = useCallback(() => {
    //   setOpenNewLang(false);
    //   // Fix for an issue with a Radix Dialog open from a Radix Dropdownmenu.
    //   setTimeout(
    //     () => document.body.style.removeProperty('pointer-events'),
    //     500
    //   );
    // }, []);

    return (
      <>
        <DropdownMenu
          trigger={
            trigger ?? (
              <span className={style.defaultTrigger}>
                <Button text={getLangName(picked)} fullWidth />
              </span>
            )
          }
          contents={menuContents}
        />
        {/* <NewLangDialog
          open={openNewLang}
          onLangAdded={onPickLang}
          onClose={closeLangDialog}
        /> */}
      </>
    );
  }
);
