import { ReactNode } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { FirebaseProvider } from './firebase';
import { ApiProvider } from './api';
import { AuthProvider } from './auth';

export { useFirebase } from './firebase';
export { useApi } from './api';
export { useAuth } from './auth';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

export function Providers({ children }: { children: ReactNode }) {
  return (
    <FirebaseProvider>
      <ApiProvider>
        <AuthProvider>
          <Elements stripe={stripePromise}>{children}</Elements>
        </AuthProvider>
      </ApiProvider>
    </FirebaseProvider>
  );
}
