import { Toaster } from 'react-hot-toast';

import { Views } from 'views';
import 'ui/tokens.css';
import 'ui/reset.css';
import 'ui/global.css';

function App() {
  return (
    <>
      <Views />
      <Toaster
        position="bottom-left"
        toastOptions={{
          style: {
            borderLeft: '8px solid',
            borderRadius: 8,
          },
          success: {
            style: {
              backgroundColor: 'var(--color-content-success-light)',
              borderColor: 'var(--color-content-success)',
            },
            iconTheme: {
              primary: 'var(--color-content-success)',
              secondary: '#fff',
            },
          },
          error: {
            duration: 6000,
            style: {
              backgroundColor: 'var(--color-content-error-light)',
              borderColor: 'var(--color-content-error)',
            },
            iconTheme: {
              primary: 'var(--color-content-error)',
              secondary: '#fff',
            },
          },
        }}
      />
    </>
  );
}

export default App;
