import type { HTTPClient } from './client';
import type { Project, Domain, DomainResponse } from './types';
import { trackCustomDomainConfigured } from 'datalayer';

export const initDomains = (client: HTTPClient) => ({
  add: (project: Project, domain: Domain) => {
    trackCustomDomainConfigured({
      project_id: project.id,
      project_name: project.siteName,
      default_language: project.language,
      number_of_pages: project.totalPages,
      CMS_collections: project.totalCollections,
      symbols: project.totalSymbols,
    });

    return client.post<Domain>('v1/domains', domain);
  },
  list: (projectId: string) => {
    return client.get<DomainResponse[]>(`v1/projects/${projectId}/domains`);
  },
  check: (domainId: string) => {
    return client.patch<{ isReady: boolean }>(`v1/domains/${domainId}`);
  },
  main: (domainId: string) => {
    return client.put<void>(`v1/domains/${domainId}`);
  },
  remove: (domainId: string) => {
    return client.delete<void>(`v1/domains/${domainId}`);
  },
});
