import {
  forwardRef,
  useRef,
  useEffect,
  useCallback,
  ChangeEventHandler,
} from 'react';
import type { Props } from './TextArea.types';
import style from './TextArea.module.css';

export const TextArea = forwardRef<HTMLTextAreaElement | null, Props>(
  ({ onChange, fullHeight, ...rest }, ref) => {
    const measure = useRef<HTMLDivElement>(null);

    const fit = useCallback(() => {
      if (!fullHeight && measure.current) {
        const input = measure.current.nextElementSibling as HTMLTextAreaElement;
        measure.current.innerText = `${input.value} `;
        input.style.height = `${measure.current.clientHeight}px`;
      }
    }, [fullHeight]);

    const change = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
      (event) => {
        onChange?.(event);
        fit();
      },
      [onChange, fit]
    );

    useEffect(fit, [fit]);

    return (
      <div className={style.wrapper + ' ' + style.fullHeight}>
        <div ref={measure} className={style.measure} />
        <textarea
          {...rest}
          ref={ref}
          className={style.input + ' ' + style.fullHeight}
          onChange={change}
          data-testid="textarea"
        />
      </div>
    );
  }
);
