import { memo, useCallback } from 'react';
import type { ChangeEvent } from 'react';
import type { Props } from './FileInput.types';

export const FileInput = memo<Props>(({ onFile, accept, disabled }) => {
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.currentTarget.files?.[0];
      event.currentTarget.files = new DataTransfer().files;

      if (file && onFile) {
        onFile(file);
      }
    },
    [onFile]
  );

  return (
    <input
      type="file"
      accept={accept}
      onChange={onChange}
      disabled={disabled}
    />
  );
});
