import { forwardRef } from 'react';

import { CircularProgress } from '../CircularProgress';

import type { Props } from './Button.types';
import style from './Button.module.css';

export const Button = forwardRef<HTMLButtonElement | null, Props>(
  (
    {
      text,
      icon: Icon,
      iconPosition,
      fullWidth,
      onClick,
      type,
      size = 'medium',
      variant = 'contained',
      look = 'standard',
      progress,
      ...rest
    },
    ref
  ) => {
    if (Icon && !text) {
      return (
        <button
          {...rest}
          ref={ref}
          type={type || (onClick ? 'button' : 'submit')}
          onClick={onClick}
          className={`${style[look]} ${style.icon} ${style[variant]} ${style[size]}`}
        >
          <i>
            <Icon />
          </i>
          {progress && (
            <div className={style.progress}>
              <CircularProgress size={size} />
            </div>
          )}
        </button>
      );
    }

    return (
      <button
        {...rest}
        ref={ref}
        type={type || (onClick ? 'button' : 'submit')}
        onClick={onClick}
        className={`${style[look]} ${style[variant]} ${style[size]}`}
        style={Object.assign(
          { width: fullWidth ? '100%' : 'unset' },
          rest.style
        )}
      >
        {(!iconPosition || iconPosition === 'left') && Icon && (
          <i className={style.startIcon}>
            <Icon />
          </i>
        )}
        <span>{text}</span>
        {progress && (
          <div className={style.progress}>
            <CircularProgress size={size} />
          </div>
        )}
        {iconPosition === 'right' && Icon && (
          <i className={style.endIcon}>
            <Icon />
          </i>
        )}
      </button>
    );
  }
);
