import { getAuth, getIdToken } from 'firebase/auth';

import type { FirebaseApp } from 'firebase/app';

type FetchSettings = {
  method: string;
  path: string;
  params?: Record<string, any>;
  body?: any;
};

export class HTTPClient {
  public readonly firebase: FirebaseApp;
  protected readonly root: string;
  protected readonly getToken: () => Promise<string>;

  public constructor(firebase: FirebaseApp, root: string) {
    this.firebase = firebase;
    this.getToken = () => getIdToken(getAuth(firebase).currentUser!);
    this.root = root.replace(/\/$/, '');
  }

  protected async getHeaders() {
    return { authorization: `Bearer ${await this.getToken()}` };
  }

  protected getUrl(path: string, params: Record<string, any> = {}) {
    const url = `${this.root}/${path.replace(/^\//, '')}`;

    const values = Object.entries(params).filter(
      ([, value]) => value !== undefined && value !== null
    );

    if (values.length < 1) {
      return url;
    }

    const qs = new URLSearchParams();

    values.forEach(([key, value]) => qs.set(key, value));

    return `${url}?${new URLSearchParams(params)}`;
  }

  protected async fetch<D = any>({
    method,
    path,
    params,
    body,
  }: FetchSettings) {
    const url = this.getUrl(path, params);
    const headers: Record<string, string> = await this.getHeaders();
    const settings: RequestInit = { method, headers };

    if (body instanceof File) {
      headers['content-type'] = body.type;
      settings.body = body;
    } else if (body) {
      headers['content-type'] = 'application/json';
      settings.body = JSON.stringify(body);
    }

    const response = await fetch(url, settings);

    const data = response.headers
      .get('content-type')
      ?.includes('application/json')
      ? await response.json()
      : null;

    if (response.status === 423 && window.location.pathname !== '/migration') {
      window.location.assign('/migration');
    } else if (response.status > 399) {
      // eslint-disable-next-line no-throw-literal
      throw data;
    }

    return data as D;
  }

  public get<D = any>(path: string, params?: Record<string, any>) {
    return this.fetch<D>({ method: 'GET', path, params });
  }

  public post<D = any>(path: string, body?: any, params?: Record<string, any>) {
    return this.fetch<D>({ method: 'POST', path, body, params });
  }

  public put<D = any>(path: string, body?: any, params?: Record<string, any>) {
    return this.fetch<D>({ method: 'PUT', path, body, params });
  }

  public patch<D = any>(
    path: string,
    body?: any,
    params?: Record<string, any>
  ) {
    return this.fetch<D>({ method: 'PATCH', path, body, params });
  }

  public delete<D = any>(path: string, params?: Record<string, any>) {
    return this.fetch<D>({ method: 'DELETE', path, params });
  }

  public upload<D = any>(
    path: string,
    file: File,
    onProgress?: (progress: number) => void
  ) {
    return new Promise<D>(async (resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          onProgress?.((100 * event.loaded) / event.total);
        }
      });

      xhr.addEventListener('loadend', () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          reject();
        }
      });

      xhr.open('POST', this.getUrl(path), true);
      xhr.setRequestHeader('content-type', 'application/octet-stream');
      xhr.setRequestHeader('authorization', `Bearer ${await this.getToken()}`);
      xhr.send(file);
    });
  }
}
