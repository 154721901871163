import { AlertIcon, SuccessIcon, InfoFullIcon, CloseIconButton } from 'ui';

import style from './FeedbackToast.module.css';

export const FeedbackToast = ({
  type,
  text,
  textSize,
  minimal,
  margin = 'none',
  onClose,
}: {
  type: 'warning' | 'success' | 'info';
  text: string | JSX.Element;
  textSize?: 'small';
  minimal?: boolean;
  margin?: 'top' | 'bottom' | 'both' | 'none';
  onClose?: () => void;
}) => (
  <div
    className={
      style.toast +
      ' ' +
      style[type] +
      ' ' +
      style[margin] +
      (minimal ? ' ' + style.minimal : '')
    }
  >
    <i>
      {type === 'warning' ? (
        <AlertIcon />
      ) : type === 'success' ? (
        <SuccessIcon />
      ) : type === 'info' ? (
        <InfoFullIcon />
      ) : null}
    </i>
    <div
      className={
        style.content + (textSize === 'small' ? ' ' + style.small : '')
      }
    >
      {text}
    </div>
    {onClose && (
      <CloseIconButton rootClassName={style.close} onClick={onClose} />
    )}
  </div>
);
