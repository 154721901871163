import style from './CircularProgress.module.css';

export const CircularProgress = ({
  size = 'medium',
  thickness = 'thin',
}: {
  size?: 'small' | 'medium' | 'big';
  thickness?: 'thin' | 'thick';
}) => (
  <span className={`${style.root} ${style[size]}`} role="progressbar">
    <svg viewBox="22 22 44 44" style={{ display: 'block' }}>
      <circle
        className={style.circle}
        cx="44"
        cy="44"
        r={thickness === 'thin' ? '20.2' : '19'}
        fill="none"
        strokeWidth={thickness === 'thin' ? '3.6' : '6'}
      />
    </svg>
  </span>
);
