//@todo CLOSING
import {
  createContext,
  useContext,
  memo,
  useRef,
  useState,
  useEffect,
  useCallback,
  ReactNode,
  useMemo,
} from 'react';
import { useParams, useNavigate, Outlet } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import useSWR, { useSWRConfig } from 'swr';

import { useApi } from 'providers';
import { ProjectOnBoardingProvider, ImageGalleryProvider } from 'partials';
import {
  Button,
  DropdownContent,
  SpinnerContent,
  AlertIcon,
  ChevronUpIcon,
  Dialog,
  HelpIcon,
  SimpleTooltip,
  Link,
} from 'ui';
import { PublishMenu } from './PublishMenu';

import type { Context } from './types';
import type { SwitcherType } from 'providers/api/types';

import style from './style.module.css';

export const context = createContext(null as any as Context);
export const useProject = () => useContext(context);
const { Provider } = context;

export type BuildError =
  | {
      type: 'UNREACHABLE_HTML';
      projectId: string;
      htmlUrl: string;
    }
  | {
      type: 'TEMPLATE_NOT_FOUND';
      projectId: string;
      templateId: string;
    }
  | {
      type:
        | 'PROJECT_NOT_FOUND'
        | 'PROJECT_NOT_SYNCED'
        | 'MISSING_SECRETS'
        | 'UNKNOWN_ERROR';
      projectId: string;
    };

export default memo(() => {
  const api = useApi();
  const nodeTimerId = useRef<any>(null);
  const unsaved = useRef(false);
  const lastProject = useRef<any>();
  const navigateAfterUpdate = useRef('');
  const { projectId } = useParams() as Record<string, string>;
  const [context, setContext] = useState<Context | null>(null);
  // const [publishing, setPublishing] = useState(false);
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();

  const alternativeDataRef = useRef<
    Record<string, { value: string; alternativeKey: string }>
  >({});

  const { data: project, error } = useSWR(`projects/${projectId}`, () =>
    api.projects.getV2(projectId)
  );

  const [currentAlternative, setCurrentAlternative] = useState<string>();

  const { data: url } = useSWR(
    `project/${projectId}/url/${currentAlternative ?? ''}`,
    () => api.projects.getUrl(projectId, currentAlternative)
  );

  const updateContext = useCallback(
    (project: Context['project']) => {
      lastProject.current = project;

      setContext({
        project,
        setAlternativeId: (id) => setCurrentAlternative(id),
        increaseProjectDomainsLimit: () => {
          // @todo should we save this or just sync the local model?
          console.log('TODO increase project domains limit');
          // updateAndSave(
          //   {
          //     limits: {
          //       ...project.limits,
          //       domains: 1,
          //     },
          //   },
          //   0
          // );
        },
        createAlternativeNode: (data) => {
          return new Promise((resolve) => {
            api.projects
              .createNodeAlternative(project.id, data)
              .then((alternative) => resolve(alternative.id));
          });
        },
        updateAlternativeNode: (value, nodeAlternativeId, alternativeKey) => {
          alternativeDataRef.current[nodeAlternativeId] = {
            value,
            alternativeKey,
          };
          clearTimeout(nodeTimerId.current);
          return new Promise((resolve) => {
            nodeTimerId.current = setTimeout(() => {
              const promises = [];
              const updatedNodes: string[] = [];
              for (const key in alternativeDataRef.current) {
                updatedNodes.push(
                  alternativeDataRef.current[key].alternativeKey
                );
                promises.push(
                  api.projects.updateNodeAlternative(
                    alternativeDataRef.current[key].value,
                    key
                  )
                );
              }
              Promise.all(promises).then(() => resolve(updatedNodes));
              alternativeDataRef.current = {};
            }, 2000);
          });
        },
        removeAlternativeNode: (nodeAlternativeId) => {
          delete alternativeDataRef.current[nodeAlternativeId];
          return api.projects.deleteNodeAlternative(nodeAlternativeId);
        },
        // PAGES
        updatePageAlternative: (
          currentPageAlternative,
          alternativeId,
          locale,
          update
        ) => {
          clearTimeout(nodeTimerId.current);
          nodeTimerId.current = setTimeout(() => {
            mutate(
              `projects/${project.id}/alternatives/pages/${currentPageAlternative.pageId}/${locale}`,
              () =>
                api.projects
                  .updatePageAlternative(alternativeId, update)
                  .then(() => ({ ...currentPageAlternative, ...update }))
                  .catch((error) => {
                    toast.error(
                      'Page alternative could not be updated. \n' +
                        error.message
                    );
                    return currentPageAlternative;
                  }),
              {
                optimisticData: () => ({
                  ...currentPageAlternative,
                  ...update,
                }),
                rollbackOnError: true,
                populateCache: true,
                revalidate: false,
              }
            ).then(() => mutate(`projects/${project.id}/pages`));
          }, 2000);
        },
        // COLLECTION ITEMS
        createItemFieldAlternative: (itemId, fieldId, locale, value) => {
          return new Promise((resolve) => {
            api.projects
              .createItemFieldAlternative(project.id, {
                itemId,
                fieldId,
                locale,
                value,
              })
              .then((alternative) => resolve(alternative.id));
          });
        },
        updateItemFieldAlternative: (
          value,
          fieldAlternativeId,
          alternativeKey
        ) => {
          alternativeDataRef.current[fieldAlternativeId] = {
            value,
            alternativeKey,
          };
          clearTimeout(nodeTimerId.current);
          return new Promise((resolve) => {
            nodeTimerId.current = setTimeout(() => {
              const promises = [];
              const updatedFields: string[] = [];
              for (const key in alternativeDataRef.current) {
                updatedFields.push(
                  alternativeDataRef.current[key].alternativeKey
                );
                promises.push(
                  api.projects.updateItemFieldAlternative(
                    alternativeDataRef.current[key].value,
                    key
                  )
                );
              }
              Promise.all(promises).then(() => resolve(updatedFields));
              alternativeDataRef.current = {};
            }, 2000);
          });
        },
        removeItemFieldAlternative: (alternativeFieldId) => {
          delete alternativeDataRef.current[alternativeFieldId];
          return api.projects.removeFieldAlternative(alternativeFieldId);
        },
        // COLLECTION OPTION FIELDS
        createOptionAlternative: (
          collectionId,
          fieldId,
          optionId,
          locale,
          value
        ) => {
          return new Promise((resolve) => {
            api.projects
              .createCollectionOptionAlternative(project.id, {
                collectionId,
                fieldId,
                optionId,
                locale,
                value,
              })
              .then((alternative) => resolve(alternative.id));
          });
        },
        updateOptionAlternative: (
          value,
          optionAlternativeId,
          alternativeKey
        ) => {
          alternativeDataRef.current[optionAlternativeId] = {
            value,
            alternativeKey,
          };
          clearTimeout(nodeTimerId.current);
          return new Promise((resolve) => {
            nodeTimerId.current = setTimeout(() => {
              const promises = [];
              const updatedFields: string[] = [];
              for (const key in alternativeDataRef.current) {
                updatedFields.push(
                  alternativeDataRef.current[key].alternativeKey
                );
                promises.push(
                  api.projects.updateCollectionOptionAlternative(
                    alternativeDataRef.current[key].value,
                    key
                  )
                );
              }
              Promise.all(promises).then(() => resolve(updatedFields));
              alternativeDataRef.current = {};
            }, 2000);
          });
        },
        removeOptionAlternative: (alternativeOptionId) => {
          delete alternativeDataRef.current[alternativeOptionId];
          return api.projects.removeCollectionOptionAlternative(
            alternativeOptionId
          );
        },
        // OTHER
        alternativeSelector: (type: SwitcherType) => {
          api.projects.updateAlternativeSelector(project.id, {
            alternativeSelector: type,
          });
        },
      });
    },
    [api, mutate]
  );

  useEffect(() => {
    if (project) updateContext(project);
  }, [project, updateContext]);

  const [buildErrors, setBuildErrors] = useState<BuildError[]>();
  const [openErrorsDialog, setOpenErrorsDialog] = useState(false);

  useEffect(() => {
    if (project?.stagingBuildId) {
      fetch(
        `${process.env.REACT_APP_FIREBASE_DATABASE}/build/${projectId}/${project.stagingBuildId}.json`
      ).then((res) =>
        res.json().then(
          (
            value: null | {
              type: string;
              warnings?: string[];
              warnings2?: BuildError[];
            }
          ) => {
            if (value?.type === 'BUILD_END') {
              // TODO migration process, it will be: setBuildSuccess(value.warnings);
              setBuildErrors(value.warnings2 || (value.warnings as any));
            }
          }
        )
      );
    }
  }, [project?.stagingBuildId, projectId]);

  // useEffect(() => {
  //   const listener = (e: any) => {
  //     const id = e.detail?.id;
  //     if (projectId === id) {
  //       fetchProject();
  //     }
  //   };

  //   window.addEventListener('projectSynced', listener);
  //   return () => window.removeEventListener('projectSynced', listener);
  // }, [projectId, fetchProject]);

  useEffect(() => {
    if (navigateAfterUpdate.current) {
      navigate(navigateAfterUpdate.current);
      navigateAfterUpdate.current = '';
    }
  }, [context, navigate]);

  useEffect(() => {
    const preventDataLost = (event: any) => {
      if (unsaved.current) {
        event.returnValue = 'Changes that you made may not be saved.';
        return event.returnValue;
      }
    };

    window.addEventListener('beforeunload', preventDataLost);
    return () => window.removeEventListener('beforeunload', preventDataLost);
  }, []);

  const [openPublishMenu, setOpenPublishMenu] = useState<boolean>();

  useEffect(() => {
    // Fix for an issue with a Radix Dialog open from a Radix Dropdownmenu.
    // TODO try with MutationObserver instead of setTimeout.
    if (!openErrorsDialog) {
      setTimeout(
        () => document.body.style.removeProperty('pointer-events'),
        500
      );
    }
  }, [openErrorsDialog]);

  const errorsList = useMemo(
    () =>
      buildErrors
        ? Object.entries(
            buildErrors.reduce((acc, curr) => {
              switch (curr.type) {
                case 'UNREACHABLE_HTML':
                  if (acc['HTML not reachable']) {
                    acc['HTML not reachable'].values!.push(curr.htmlUrl);
                  } else {
                    acc['HTML not reachable'] = {
                      tip: 'Make sure you have published the following pages in Webflow.',
                      values: [curr.htmlUrl],
                    };
                  }
                  break;
                case 'TEMPLATE_NOT_FOUND':
                  if (acc['Template not found']) {
                    acc['Template not found'].values!.push(curr.templateId);
                  } else {
                    acc['Template not found'] = {
                      tip: 'Re-synchronize your project so that Polyflow can recognize the page.',
                      values: [curr.templateId],
                    };
                  }
                  break;
                case 'PROJECT_NOT_SYNCED':
                case 'PROJECT_NOT_FOUND':
                  acc['Project not synced or not found'] = {
                    tip: (
                      <span>
                        Synchronize your project with our browser extension.{' '}
                        <a
                          href="https://youtu.be/RcN8_HvH-Bk"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Find here all the instructions
                        </a>
                      </span>
                    ),
                  };
                  break;
                default:
                  acc[curr.type] = {
                    tip: 'Please contact support',
                  };
              }
              return acc;
            }, {} as Record<'HTML not reachable' | 'Template not found' | 'Project not synced or not found' | 'MISSING_SECRETS' | 'UNKNOWN_ERROR', { tip: string | ReactNode; values?: string[] }>)
          )
        : [],
    [buildErrors]
  );

  if (error) {
    return (
      <div className={style.errorView}>
        <span>Something went wrong</span>
        <span>
          Project with id "<strong>{projectId}</strong>" could not be fetched.
        </span>
        <Link text="Back to projects" url="/projects" internal />
      </div>
    );
  }

  if (!context) {
    return (
      <div className={style.spinner}>
        <SpinnerContent />
      </div>
    );
  }

  return (
    <>
      <Provider value={context}>
        <ProjectOnBoardingProvider>
          <ImageGalleryProvider projectId={projectId}>
            <Outlet />
            <div className={style.bar}>
              {context.project.isPastDue && (
                <div className={style.alert}>
                  <AlertIcon />
                  <span className={style.nonPaymentAlert}>
                    <strong>Update payment.</strong> Your project has been
                    suspended for non-payment.{' '}
                    {context.project.lastInvoiceLink && (
                      <a
                        href={context.project.lastInvoiceLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Restore your services by paying here.
                      </a>
                    )}
                  </span>
                </div>
              )}
              <div className={style.settings}>
                <div className={style.siteName}>{context.project.siteName}</div>
                <div className={style.actions}>
                  {buildErrors && (
                    <Button
                      text={'Current build errors (' + buildErrors.length + ')'}
                      size="small"
                      look="danger"
                      variant="outlined"
                      onClick={() => setOpenErrorsDialog(true)}
                    />
                  )}
                  {url && (
                    <DropdownContent
                      offset={20}
                      trigger={
                        <Button
                          icon={ChevronUpIcon}
                          text="Publish"
                          variant="outlined"
                          look="highlight"
                          size="small"
                        />
                      }
                      open={openPublishMenu}
                      openChange={() => setOpenPublishMenu(undefined)}
                      content={
                        project && (
                          <PublishMenu
                            project={project}
                            url={url}
                            onOpenErrors={() => {
                              setOpenErrorsDialog(true);
                              setOpenPublishMenu(false);
                            }}
                          />
                        )
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </ImageGalleryProvider>
        </ProjectOnBoardingProvider>
      </Provider>
      <Dialog
        title="Errors generated during the last build"
        open={openErrorsDialog}
        onOpenChange={setOpenErrorsDialog}
        closeButton="Close"
        size="l"
      >
        <div className={style.warningsContainer}>
          {errorsList.map((list, i, arr) => {
            return (
              <div key={i} className={style.warningsGroup}>
                <p>
                  {list[0]}{' '}
                  <SimpleTooltip
                    text={list[1].tip}
                    align={
                      arr.length === 1 || i < arr.length - 1 ? 'bottom' : 'top'
                    }
                    width={250}
                  >
                    <span>
                      <HelpIcon />
                    </span>
                  </SimpleTooltip>
                </p>
                {list[1].values?.length && (
                  <ul>
                    {list[1].values.map((value, j) => (
                      <li key={j}>{value}</li>
                    ))}
                  </ul>
                )}
              </div>
            );
          })}
        </div>
      </Dialog>
    </>
  );
});
