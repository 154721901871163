import { memo, useState, useCallback } from 'react';
import LogoPolyflowCircle from '../../assets/LogoPolyflowCircle.svg';
import style from './HubSpot.module.css';

if (!(window as any).hsConversationsSettings) {
  const script = window.document.createElement('script');
  script.src = '//js-eu1.hs-scripts.com/26072440.js';
  window.document.head.appendChild(script);
  (window as any).hsConversationsSettings = {
    loadImmediately: false,
    inlineEmbedSelector: '#hubspot',
  };
}

export const HubSpot = memo(() => {
  const [isShown, setIsShown] = useState(() => window.localStorage.getItem('IS_HUBSPOT_SHOWN') !== 'false');
  const [isChatShown, setIsChatShown] = useState(false);

  const toggle = useCallback(() => {
    setIsShown(isShown => {
      window.localStorage.setItem('IS_HUBSPOT_SHOWN', isShown ? 'false' : 'true');
      return !isShown;
    });
  }, []);

  const openChat = useCallback(() => {
    setIsChatShown(true);
    (window as any).HubSpotConversations?.widget.load();
    (window as any).HubSpotConversations?.widget.open();
  }, []);

  const closeChat = useCallback(() => {
    setIsChatShown(false);
    (window as any).HubSpotConversations?.widget.remove();
  }, []);

  return (
    <>
      <div className={style.trigger}>
        <div className={style.icon} role="button" onClick={toggle}>
          <img alt="Polyflow" src={LogoPolyflowCircle} />
        </div>
      </div>
      <div className={isShown ? style.container : style.hiddenContainer}>
        <div className={isChatShown ? style.hiddenInnerContainer : style.innerContainer}>
          <div className={style.video}>
            <span>Quick Polyflow overview</span>
            <div>
              <iframe src="https://www.youtube.com/embed/HZicZrd5HI0"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
            </div>
          </div>
          <ul className={style.menu}>
            <li>
              <a href="https://guides.polyflow.co/getting-started/install-our-browser-extension" target="_blank" rel="noreferrer"
                 className={style.link}><span className={style.number}>1</span>Install Chrome extension</a>
            </li>
            <li>
              <a href="https://guides.polyflow.co/getting-started/sync-your-project-with-polyflow" target="_blank" rel="noreferrer"
                 className={style.link}><span className={style.number}>2</span>Sync your webflow project</a>
            </li>
            <li>
              <a href="https://guides.polyflow.co/getting-started/create-language-alternatives" target="_blank" rel="noreferrer"
                 className={style.link}><span className={style.number}>3</span>Start translating</a>
            </li>
            <li>
              <a href="https://guides.polyflow.co/publish-your-project/add-a-custom-domain" target="_blank" rel="noreferrer"
                 className={style.link}><span className={style.number}>4</span>Publish your project</a>
            </li>
          </ul>
          <div className={style.buttons}>
            <a className={style.help} href="mailto:hi@polyflow.co">Ask for help</a>
            <a className={style.help} href="https://guides.polyflow.co/getting-started/overview"
               target="_blank" rel="noreferrer">Help center</a>
          </div>
          <div className={style.chat} role="button" onClick={openChat}>Live chat</div>
        </div>
        <div className={isChatShown ? style.innerContainer : style.hiddenInnerContainer} id="hubspot">
          <div className={style.close} role="button" onClick={closeChat}>Close
            chat
          </div>
        </div>
      </div>
    </>
  );
});
