import { createContext, useContext, memo, useState, useCallback } from 'react';
import { useApi, useAuth } from 'providers';
import type { Context, Props } from './ProjectOnBoarding.types';

export const context = createContext(null as any as Context);
export const useProjectOnBoarding = () => useContext(context);

export const ProjectOnBoardingProvider = memo<Props>(({ children }) => {
  const api = useApi();
  const { user } = useAuth();

  const [onBoardingStep, setOnBoardingStep] = useState(() => !Boolean(user?.state.skipProjectOnboarding) ? 1 : undefined);
  const [showTutorialsTip, setShowTutorialsTip] = useState(false);

  const skipProjectOnBoarding = useCallback(() => {
    api.user.updateState({ skipProjectOnboarding: true });
    setShowTutorialsTip(true);
    setOnBoardingStep(undefined);
  }, [api.user]);

  return (
    <context.Provider value={{ onBoardingStep, setOnBoardingStep, skipProjectOnBoarding, showTutorialsTip, setShowTutorialsTip }}>
      {children}
    </context.Provider>
  );
});
