import { useRef, useEffect, useState, useCallback } from 'react';

type Context = {
  boolean: (name: string) => boolean;
  integer: (name: string) => number;
  float: (name: string) => number;
  value: (name: string) => string;
  values: <T extends string>(...names: T[]) => Record<T, string>;
  array: (name: string) => string[];
  arrays: <T extends string>(...names: T[]) => Record<T, string[]>;
  file: (name: string) => File | null;
  files: <T extends string>(...names: T[]) => Record<T, File | null>;
};

export type SubmitHandler = (ctx: Context) => any;

export function useForm(submit: SubmitHandler) {
  const ref = useRef<HTMLFormElement | null>();
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<any>(null);

  const boolean = useCallback((name: string) => {
    return ref.current
      ? (ref.current.elements as any as Record<string, HTMLInputElement>)[name]
          ?.checked ?? false
      : false;
  }, []);

  const integer = useCallback((name: string) => {
    return ref.current
      ? Number.parseInt(
          (ref.current.elements as any as Record<string, HTMLInputElement>)[
            name
          ]?.value,
          10
        )
      : NaN;
  }, []);

  const float = useCallback((name: string) => {
    return ref.current
      ? Number.parseFloat(
          (ref.current.elements as any as Record<string, HTMLInputElement>)[
            name
          ]?.value
        )
      : NaN;
  }, []);

  const value = useCallback((name: string) => {
    return ref.current
      ? (ref.current.elements as any as Record<string, HTMLInputElement>)[name]
          ?.value ?? ''
      : '';
  }, []);

  const values = useCallback(
    (...names: string[]) => {
      return names.reduce(
        (values, name) => ({ ...values, [name]: value(name) }),
        {} as any
      );
    },
    [value]
  );

  const array = useCallback((name: string) => {
    return ref.current
      ? Array.from(
          ref.current.querySelectorAll<HTMLInputElement>(`[name="${name}[]"]`)
        ).map((input) => input.value)
      : [];
  }, []);

  const arrays = useCallback(
    (...names: string[]) => {
      return names.reduce(
        (values, name) => ({ ...values, [name]: array(name) }),
        {} as any
      );
    },
    [array]
  );

  const file = useCallback((name: string) => {
    return ref.current
      ? (ref.current.elements as any as Record<string, HTMLInputElement>)[name]
          ?.files?.[0] ?? null
      : null;
  }, []);

  const files = useCallback(
    (...names: string[]) => {
      return names.reduce(
        (values, name) => ({ ...values, [name]: file(name) }),
        {} as any
      );
    },
    [file]
  );

  const validityListener = useCallback(
    () => setIsValid(ref.current?.checkValidity() ?? false),
    []
  );

  const refCallback = useCallback(
    (form: HTMLFormElement | null) => {
      // Required to start with the right validity and to trigger
      // the main useEffect that attaches the submit listener.
      if (form) setIsValid(false);

      if (ref.current) {
        ref.current.removeEventListener('input', validityListener);
        ref.current.removeEventListener('change', validityListener);
      }

      ref.current = form;

      if (ref.current) {
        ref.current.addEventListener('input', validityListener);
        ref.current.addEventListener('change', validityListener);
      }
    },
    [validityListener]
  );

  /*useEffect(() => {
    let last: boolean;
    let raf: any;
    const check = () => {
      const now = ref.current?.checkValidity()??false;
      if (now !== last) {
        last = now;
        setIsValid(now);
      }
      raf = requestAnimationFrame(check);
    };
    check();
    return () => cancelAnimationFrame(raf);
  }, []);*/

  // This useEffect should be triggered each time the form ref changes.
  // Currently it is triggered each time the form validity changes.
  useEffect(() => {
    const form = ref.current;

    if (!form || isSubmitting) {
      return;
    }

    const listener = async (event: Event) => {
      event.preventDefault();
      setIsSubmitting(true);
      setError(null);
      try {
        await Promise.resolve(
          submit({
            boolean,
            integer,
            float,
            value,
            values,
            array,
            arrays,
            file,
            files,
          })
        );
      } catch (error) {
        setError(error);
      }
      setIsSubmitting(false);
    };

    form.addEventListener('submit', listener);
    return () => form.removeEventListener('submit', listener);
  }, [
    submit,
    isValid,
    isSubmitting,
    boolean,
    integer,
    float,
    value,
    values,
    array,
    arrays,
    file,
    files,
  ]);

  return {
    ref: refCallback,
    isValid,
    isSubmitting,
    error,
    boolean,
    integer,
    float,
    value,
    values,
    array,
    arrays,
  };
}
