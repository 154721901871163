import type { HTTPClient } from './client';
import type {
  Project,
  Symbol,
  Template,
  PageAlternative,
  NodeAlternative,
  Collection,
  CollectionItemFieldAlternative,
  CollectionOptionAlternative,
  SwitcherType,
  CollectionItemAlternative,
} from './types';
import type {
  PageResponse,
  PageAlternativeResponse,
  SymbolAlternativeResponse,
  CollectionItemsResponse,
  CollectionItemAlternativeResponse,
  OptionFieldAlternativeResponse,
  ProjectResponse,
} from './responseTypes';
import {
  trackPreviewCreation,
  trackProjectPublication,
  trackPlanUpgradedAttempt,
  trackProjectDeletion,
} from 'datalayer';
import type { Image } from './types';

export const initProjects = (client: HTTPClient) => ({
  list: () => {
    return client.get<Project[]>('v2/projects');
  },
  getV2: (projectId: string) => {
    return client.get<ProjectResponse>(`v2/projects/${projectId}`);
  },
  fixBrokenTemplates: async (projectId: string) => {
    await client.patch<Template[]>(`v2/projects/${projectId}/templates`);
  },
  getTemplates: (projectId: string) => {
    return client.get<Template[]>(`v2/projects/${projectId}/templates`);
  },
  getPages: (projectId: string) => {
    return client.get<PageResponse[]>(`v2/projects/${projectId}/pages`);
  },
  getPageAlternative: (projectId: string, pageId: string, locale: string) => {
    return client.get<PageAlternativeResponse>(
      `v2/projects/${projectId}/alternatives/pages/${pageId}/${locale}`
    );
  },
  createPage: (
    projectId: string,
    data: {
      templateId: string;
      locale: string;
      path?: string;
    }
  ) => {
    return client.post<PageResponse>(`v2/projects/${projectId}/pages`, data);
  },
  updatePage: (pageId: string, data: { name: string }) => {
    return client.patch<void>(`v2/pages/${pageId}`, data);
  },
  deletePage: (pageId: string) => {
    return client.delete<void>(`v2/pages/${pageId}`);
  },
  createPageAlternative: (
    projectId: string,
    data: {
      pageId: string;
      templateId: string;
      locale: string;
      path?: string;
    }
  ) => {
    return client.post<PageAlternative>(
      `v2/projects/${projectId}/alternatives/pages`,
      data
    );
  },
  updatePageAlternative: (
    pageAlternativeId: string,
    data: Partial<
      Omit<
        PageAlternative,
        'id' | 'projectId' | 'templateId' | 'createdAt' | 'updatedAt'
      >
    >
  ) => {
    return client.patch<void>(
      `v2/alternatives/pages/${pageAlternativeId}`,
      data
    );
  },
  deletePageAlternative: (alternativeId: string) => {
    return client.delete(`v2/alternatives/pages/${alternativeId}`);
  },
  createNodeAlternative: (
    projectId: string,
    data: {
      id: string;
      locale: string;
      value: string;
      attribute?: string;
      pageAlternativeId?: string;
    }
  ) => {
    return client.post<NodeAlternative>(
      `v2/projects/${projectId}/alternatives/nodes`,
      data
    );
  },
  translatePageAlternative: (pageAlternativeId: string) => {
    return client.patch<
      {
        id: string; // id of the original node
        nodeAlternativeId: string;
        value: string;
      }[]
    >(`v2/alternatives/pages/${pageAlternativeId}/translate`);
  },
  updateNodeAlternative: (value: string, nodeAlternativeId: string) => {
    return client.patch<void>(`v2/alternatives/nodes/${nodeAlternativeId}`, {
      value,
    });
  },
  deleteNodeAlternative: (nodeAlternativeId: string) => {
    return client.delete<void>(`v2/alternatives/nodes/${nodeAlternativeId}`);
  },
  getSymbols: (projectId: string) => {
    return client.get<Symbol[]>(`v2/projects/${projectId}/symbols`);
  },
  getSymbolAlternative: (
    projectId: string,
    symbolId: string,
    locale: string
  ) => {
    return client.get<SymbolAlternativeResponse>(
      `v2/projects/${projectId}/alternatives/symbols/${symbolId}/${locale}`
    );
  },
  getCollections: (projectId: string) => {
    return client.get<Collection[]>(`v2/projects/${projectId}/collections`);
  },
  getCollection: (projectId: string, collectionId: string) => {
    return client.get<Collection>(
      `v2/projects/${projectId}/collections/${collectionId}`
    );
  },
  getCollectionItems: (projectId: string, collectionId: string) => {
    return client.get<CollectionItemsResponse>(
      `v2/projects/${projectId}/collections/${collectionId}/items`
    );
  },
  getCollectionItemAlternative: (
    projectId: string,
    itemId: string,
    locale: string
  ) => {
    return client.get<CollectionItemAlternativeResponse>(
      `v2/projects/${projectId}/alternatives/items/${itemId}/${locale}`
    );
  },
  createCollectionItemAlternative: (
    projectId: string,
    itemId: string,
    locale: string,
    data: {
      slug?: string | null;
      title?: string | null;
      description?: string | null;
    }
  ) => {
    return client.post<CollectionItemAlternative>(
      `v2/projects/${projectId}/alternatives/items`,
      {
        itemId,
        locale,
        ...data,
      }
    );
  },
  updateCollectionItemAlternative: (
    itemAlternativeId: string,
    data: {
      slug?: string | null;
      title?: string | null;
      description?: string | null;
    }
  ) => {
    return client.patch<void>(
      `v2/alternatives/items/${itemAlternativeId}`,
      data
    );
  },
  deleteCollectionItemAlternative: (itemAlternativeId: string) => {
    return client.delete<void>(`v2/alternatives/items/${itemAlternativeId}`);
  },
  createItemFieldAlternative: (
    projectId: string,
    data: {
      itemId: string;
      fieldId: string;
      locale: string;
      value: string;
    }
  ) => {
    return client.post<CollectionItemFieldAlternative>(
      `v2/projects/${projectId}/alternatives/fields`,
      data
    );
  },
  updateItemFieldAlternative: (value: string, fieldAlternativeId: string) => {
    return client.patch<void>(`v2/alternatives/fields/${fieldAlternativeId}`, {
      value,
    });
  },
  removeFieldAlternative: (fieldAlternativeId: string) => {
    return client.delete<void>(`v2/alternatives/fields/${fieldAlternativeId}`);
  },
  getCollectionOptionFields: (projectId: string, collectionId: string) => {
    return client.get<Collection['fields']>(
      `v2/projects/${projectId}/collections/${collectionId}/options`
    );
  },
  getCollectionOptionFieldAlternative: (
    projectId: string,
    collectionId: string,
    fieldId: string,
    locale: string
  ) => {
    return client.get<OptionFieldAlternativeResponse>(
      `v2/projects/${projectId}/alternatives/options/${collectionId}/${fieldId}/${locale}`
    );
  },
  createCollectionOptionAlternative: (
    projectId: string,
    data: {
      collectionId: string;
      fieldId: string;
      optionId: string;
      locale: string;
      value: string;
    }
  ) => {
    return client.post<CollectionOptionAlternative>(
      `v2/projects/${projectId}/alternatives/options`,
      data
    );
  },
  updateCollectionOptionAlternative: (
    value: string,
    optionAlternativeId: string
  ) => {
    return client.patch<void>(
      `v2/alternatives/options/${optionAlternativeId}`,
      { value }
    );
  },
  removeCollectionOptionAlternative: (optionAlternativeId: string) => {
    return client.delete<void>(
      `v2/alternatives/options/${optionAlternativeId}`
    );
  },
  getUrl: (projectId: string, pageAlternativeId?: string) => {
    return client.get<{ custom?: string; default: string }>(
      `v2/projects/${projectId}/url`,
      pageAlternativeId ? { pageAlternativeId } : {}
    );
  },
  getSubscription: (projectId: string) => {
    return client.get<any>(`v1/projects/${projectId}/subscription`);
  },
  addToSubscription: async (
    projectId: string,
    addons: {
      customDomain?: boolean;
      languages?: number;
      paymentMethodId?: string;
    }
  ) => {
    await client.post<any>(`v1/projects/${projectId}/subscription`, addons);
  },
  createSubscriptionLink: ({
    project,
    priceId,
    productName,
    successUrl,
    cancelUrl,
  }: {
    project: Project;
    priceId: string;
    productName: string;
    successUrl: string;
    cancelUrl: string;
  }) => {
    trackPlanUpgradedAttempt({
      new_plan: productName,
      project_id: project.id,
      project_name: project.siteName,
      default_language: project.language,
      number_of_pages: project.totalPages,
      CMS_collections: project.totalCollections,
      symbols: project.totalSymbols,
    });
    return client.post<{ url: string }>('v2/stripe/subscription', {
      projectId: project.id,
      priceId,
      successUrl,
      cancelUrl,
    });
  },
  updateProjectLanguage: (projectId: string, language: string) => {
    return client.patch<void>(`v2/projects/${projectId}/language`, {
      language,
    });
  },
  remove: async (project: Project) => {
    trackProjectDeletion({
      project_id: project.id,
      project_name: project.siteName,
      default_language: project.language,
      number_of_pages: project.totalPages,
      CMS_collections: project.totalCollections,
      symbols: project.totalSymbols,
    });
    await client.delete(`v2/projects/${project.id}`);
  },
  build: (project: Project, publish = false) => {
    trackPreviewCreation({
      publish,
      project_id: project.id,
      project_name: project.siteName,
      default_language: project.language,
      number_of_pages: project.totalPages,
      CMS_collections: project.totalCollections,
      symbols: project.totalSymbols,
    });
    if (publish) {
      trackProjectPublication({
        from_preview: true,
        project_id: project.id,
        project_name: project.siteName,
        default_language: project.language,
        number_of_pages: project.totalPages,
        CMS_collections: project.totalCollections,
        symbols: project.totalSymbols,
      });
    }
    return client.post<{ buildId: string }>(
      `v2/projects/${project.id}/builds`,
      {
        publish,
      }
    );
  },
  publish: (project: Project) => {
    trackProjectPublication({
      from_preview: false,
      project_id: project.id,
      project_name: project.siteName,
      default_language: project.language,
      number_of_pages: project.totalPages,
      CMS_collections: project.totalCollections,
      symbols: project.totalSymbols,
    });
    return client.put<void>(`v2/projects/${project.id}/builds`);
  },
  updateAlternativeSelector: (
    projectId: string,
    data: { alternativeSelector: SwitcherType }
  ) => {
    return client.patch<void>(`v2/projects/${projectId}/switcher`, data);
  },
  listImages: (projectId: string) => {
    return client.get<Image[]>(`v1/projects/${projectId}/images`);
  },
  uploadImage: (projectId: string, file: File) => {
    return client.upload<Image>(`v1/projects/${projectId}/images`, file);
  },
  removeImage: async (projectId: string, imageId: string) => {
    await client.delete(`v1/projects/${projectId}/images/${imageId}`);
  },
  cancelDomainSubscription: async (projectId: string) => {
    await client.delete(`v1/projects/${projectId}/subscription/domain`);
  },
});
