import { LogoPolyflowText } from 'ui';

import type { ReactNode } from 'react';

import style from './LayoutAccess.module.css';

export const LayoutAccess = ({
  leftChildren,
  rightChildren,
}: {
  leftChildren?: ReactNode;
  rightChildren: ReactNode;
}) => {
  return (
    <div className={style.root}>
      <a href="https://www.polyflow.co/" className={style.logo}>
        <LogoPolyflowText />
      </a>
      <div>{leftChildren}</div>
      <div className={style.contents}>
        <div>{rightChildren}</div>
      </div>
    </div>
  );
};
