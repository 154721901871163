import { memo } from 'react';

import type { InputHTMLAttributes, ReactNode } from 'react';

import style from './Checkbox.module.css';

type Props = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'className' | 'style' | 'type'
> & {
  text?: string | ReactNode;
};

export const Checkbox = memo<Props>(function Checkbox({
  text,
  tabIndex = 0,
  ...rest
}) {
  return (
    <label
      className={style.checkbox + (rest.disabled ? ' ' + style.disabled : '')}
      tabIndex={tabIndex}
    >
      <input
        {...rest}
        type="checkbox"
        className={style.input}
        aria-hidden="true"
      />
      <div className={style.indicator} />
      <div className={style.label}>{text}</div>
    </label>
  );
});
