import { ReactComponent as Alert } from './Alert.svg';
import { ReactComponent as AlignBottom } from './AlignBottom.svg';
import { ReactComponent as AlignLeft } from './AlignLeft.svg';
import { ReactComponent as AlignRight } from './AlignRight.svg';
import { ReactComponent as AlignTop } from './AlignTop.svg';
import { ReactComponent as ArrowBack } from './ArrowBack.svg';
import { ReactComponent as Bag } from './Bag.svg';
import { ReactComponent as Building } from './Building.svg';
import { ReactComponent as Calendar } from './Calendar.svg';
import { ReactComponent as Chair } from './Chair.svg';
import { ReactComponent as Check } from './Check.svg';
import { ReactComponent as CheckV2 } from './Check.v2.svg';
import { ReactComponent as ChevronDown } from './ChevronDown.svg';
import { ReactComponent as ChevronLeft } from './ChevronLeft.svg';
import { ReactComponent as ChevronRight } from './ChevronRight.svg';
import { ReactComponent as ChevronUp } from './ChevronUp.svg';
import { ReactComponent as Comment } from './Comment.svg';
import { ReactComponent as Copy } from './Copy.svg';
import { ReactComponent as CopyV2 } from './Copy.v2.svg';
import { ReactComponent as Cross } from './Cross.svg';
import { ReactComponent as Edit } from './Edit.svg';
import { ReactComponent as EditV2 } from './Edit.v2.svg';
import { ReactComponent as EyeClosed } from './EyeClosed.svg';
import { ReactComponent as EyeOpen } from './EyeOpen.svg';
import { ReactComponent as Filter } from './Filter.svg';
import { ReactComponent as Floor } from './Floor.svg';
import { ReactComponent as Help } from './Help.svg';
import { ReactComponent as HorizontalDistribute } from './HorizontalDistribute.svg';
import { ReactComponent as Info } from './Info.svg';
import { ReactComponent as InfoFull } from './InfoFull.svg';
import { ReactComponent as Instagram } from './Instagram.svg';
import { ReactComponent as Map } from './Map.svg';
import { ReactComponent as Measure } from './Measure.svg';
import { ReactComponent as Menu } from './Menu.svg';
import { ReactComponent as Message } from './Message.svg';
import { ReactComponent as Minus } from './Minus.svg';
import { ReactComponent as NewPost } from './NewPost.svg';
import { ReactComponent as Notifications } from './Notifications.svg';
import { ReactComponent as Options } from './Options.svg';
import { ReactComponent as Pause } from './Pause.svg';
import { ReactComponent as Place } from './Place.svg';
import { ReactComponent as Placeholder } from './Placeholder.svg';
import { ReactComponent as Play } from './Play.svg';
import { ReactComponent as Plus } from './Plus.svg';
import { ReactComponent as Profile } from './Profile.svg';
import { ReactComponent as Question } from './Question.svg';
import { ReactComponent as Restore } from './Restore.svg';
import { ReactComponent as Rocket } from './Rocket.svg';
import { ReactComponent as Save } from './Save.svg';
import { ReactComponent as Search } from './Search.svg';
import { ReactComponent as Share } from './Share.svg';
import { ReactComponent as Sheet } from './Sheet.svg';
import { ReactComponent as Success } from './Success.svg';
import { ReactComponent as Trash } from './Trash.svg';
import { ReactComponent as Upload } from './Upload.svg';
import { ReactComponent as VerticalDistribute } from './VerticalDistribute.svg';
import { ReactComponent as Settings } from './Settings.svg';
import { ReactComponent as AddSolid } from './AddSolid.svg';
import { ReactComponent as ExternalLink } from './ExternalLink.svg';
import { ReactComponent as Dot } from './Dot.svg';
import { ReactComponent as Delete } from './Delete.svg';
import { ReactComponent as DeleteV2 } from './Delete.v2.svg';
import { ReactComponent as Lock } from './Lock.svg';
import { ReactComponent as Chrome } from './Chrome.svg';
import { ReactComponent as Warning } from './Warning.svg';

import style from './Icon.module.css';

export const AddSolidIcon = () => <AddSolid className={style.icon} />;

export const AlertIcon = () => <Alert />;

export const AlignBottomIcon = () => <AlignBottom className={style.icon} />;

export const AlignLeftIcon = () => <AlignLeft className={style.icon} />;

export const AlignRightIcon = () => <AlignRight className={style.icon} />;

export const AlignTopIcon = () => <AlignTop className={style.icon} />;

export const ArrowBackIcon = () => <ArrowBack className={style.icon} />;

export const BagIcon = () => <Bag />;

export const BuildingIcon = () => <Building className={style.icon} />;

export const CalendarIcon = () => <Calendar className={style.icon} />;

export const ChairIcon = () => <Chair className={style.icon} />;

export const CheckIcon = () => <Check className={style.icon} />;

export const CheckIconV2 = () => <CheckV2 className={style.icon} />;

export const ChevronDownIcon = () => <ChevronDown className={style.icon} />;

export const ChevronLeftIcon = () => <ChevronLeft className={style.icon} />;

export const ChevronRightIcon = () => <ChevronRight className={style.icon} />;

export const ChevronUpIcon = () => <ChevronUp className={style.icon} />;

export const CommentIcon = () => <Comment className={style.icon} />;

export const CopyIcon = () => <Copy className={style.icon} />;

export const CopyIconV2 = () => <CopyV2 className={style.icon} />;

export const CrossIcon = () => <Cross className={style.icon} />;

export const DeleteIcon = () => <Delete className={style.icon} />;

export const DeleteIconV2 = () => <DeleteV2 />;

export const DotIcon = () => <Dot className={style.icon} />;

export const EditIcon = () => <Edit className={style.icon} />;

export const EditIconV2 = () => <EditV2 />;

export const ExternalLinkIcon = () => <ExternalLink />;

export const EyeClosedIcon = () => <EyeClosed className={style.icon} />;

export const EyeOpenIcon = () => <EyeOpen className={style.icon} />;

export const FilterIcon = () => <Filter className={style.icon} />;

export const FloorIcon = () => <Floor className={style.icon} />;

export const HelpIcon = () => <Help className={style.icon} />;

export const HorizontalDistributeIcon = () => (
  <HorizontalDistribute className={style.icon} />
);

export const InstagramIcon = () => <Instagram className={style.icon} />;

export const InfoIcon = () => <Info className={style.icon} />;

export const InfoFullIcon = () => <InfoFull />;

export const MapIcon = () => <Map className={style.icon} />;

export const MeasureIcon = () => <Measure className={style.icon} />;

export const MenuIcon = () => <Menu className={style.icon} />;

export const MessageIcon = () => <Message className={style.icon} />;

export const MinusIcon = () => <Minus className={style.icon} />;

export const NewPostIcon = () => <NewPost className={style.icon} />;

export const NotificationsIcon = () => <Notifications className={style.icon} />;

export const OptionsIcon = () => <Options className={style.icon} />;

export const PauseIcon = () => <Pause className={style.icon} />;

export const PlaceIcon = () => <Place className={style.icon} />;

export const PlaceholderIcon = () => <Placeholder className={style.icon} />;

export const PlayIcon = () => <Play className={style.icon} />;

export const PlusIcon = () => <Plus className={style.icon} />;

export const ProfileIcon = () => <Profile className={style.icon} />;

export const QuestionIcon = () => <Question />;

export const RestoreIcon = () => <Restore className={style.icon} />;

export const RocketIcon = () => <Rocket className={style.icon} />;

export const SaveIcon = () => <Save className={style.icon} />;

export const SearchIcon = () => <Search className={style.icon} />;

export const ShareIcon = () => <Share className={style.icon} />;

export const SheetIcon = () => <Sheet className={style.icon} />;

export const SuccessIcon = () => <Success />;

export const TrashIcon = () => <Trash className={style.icon} />;

export const UploadIcon = () => <Upload className={style.icon} />;

export const VerticalDistributeIcon = () => (
  <VerticalDistribute className={style.icon} />
);

export const SettingsIcon = () => <Settings className={style.icon} />;

export const LockIcon = () => <Lock />;

export const ChromeIcon = () => <Chrome />;

export const WarningIcon = () => <Warning />;
