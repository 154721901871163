import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { LayoutAccess } from 'partials';

import { useApi } from 'providers';
import { AnchorButton } from 'ui';

import style from './style.module.css';

import appIllustration from 'assets/appIllustration.svg';

export default memo(() => {
  const api = useApi();
  const navigate = useNavigate();

  return (
    <LayoutAccess
      leftChildren={
        <img
          src={appIllustration}
          className={style.illustration}
          alt="Polyflow screens simplified as illustrations"
        />
      }
      rightChildren={
        <div className={style.formWrapper}>
          <h2 className={style.sentence}>
            We have a <span>stunning and fresh</span> new version
          </h2>
          <AnchorButton
            text="Request update to the new version"
            href="https://mail.google.com/mail/?view=cm&source=mailto&to=hi@polyflow.co&su=I%20want%20to%20update%20my%20project%20to%20the%20new%20version&body=Hi%20there!%0D%0A%0D%0AI'm%20%5BYour%20name%5D%20from%20%5BYour%20company%5D%0D%0A%0D%0AI%20want%20to%20update%20my%20Polyflow%20account%20to%20the%20new%20version%0D%0A%0D%0ABest%2C"
            look="highlight"
            variant="outlined"
            target="_blank"
            rel="noreferrer"
          />
          <button
            className={style.backToLogin}
            onClick={() => {
              api.auth.signOut().then(() => navigate('/login'));
            }}
          >
            Login with another account
          </button>
        </div>
      }
    />
  );
});
