//@todo CLOSING
import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { useAuth } from 'providers';
import { SpinnerContent /* HubSpot */ } from 'ui';
import { UserContent } from '../partials';

const CreateAccount = lazy(() => import('./CreateAccount'));
const Login = lazy(() => import('./Login'));
//const Signup = lazy(() => import('./Signup'));
const ExtensionLogin = lazy(() => import('./ExtensionLogin'));
const PasswordRecovery = lazy(() => import('./PasswordRecovery'));
const Account = lazy(() => import('./Account'));
const VerifyEmail = lazy(() => import('./VerifyEmail'));
const ChangePassword = lazy(() => import('./ChangePassword'));
const Dashboard = lazy(() => import('./Dashboard'));
const Projects = lazy(() => import('./Projects'));
const Project = lazy(() => import('./Project'));
const ProjectElements = lazy(() => import('./ProjectElements'));
const ProjectSettings = lazy(() => import('./ProjectSettings'));
const ProjectPagesTable = lazy(
  () => import('./ProjectElements/ProjectPagesTable')
);
const ProjectCollectionsTable = lazy(
  () => import('./ProjectElements/ProjectCollectionsTable')
);
const ProjectCollectionElements = lazy(
  () => import('./ProjectElements/ProjectCollectionElements')
);
const ProjectCollectionItemsTable = lazy(
  () => import('./ProjectElements/ProjectCollectionItemsTable')
);
const ProjectCollectionOptionsTable = lazy(
  () => import('./ProjectElements/ProjectCollectionOptionsTable')
);
const ProjectSymbolsTable = lazy(
  () => import('./ProjectElements/ProjectSymbolsTable')
);
const ProjectTemplatesTable = lazy(
  () => import('./ProjectElements/ProjectTemplatesTable')
);
const PageAlternative = lazy(() => import('./PageAlternative'));
const CollectionItemAlternative = lazy(
  () => import('./CollectionItemAlternative')
);
const CollectionOptionAlternative = lazy(
  () => import('./CollectionOptionAlternative')
);
const SymbolAlternative = lazy(() => import('./SymbolAlternative'));
const NotFound = lazy(() => import('./NotFound'));

export function Views() {
  const { user, firebaseUser, isChecking } = useAuth();

  if (isChecking) {
    return (
      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SpinnerContent />
      </div>
    );
  }

  if (!user && firebaseUser) {
    return (
      <Suspense>
        <CreateAccount />
      </Suspense>
    );
  }

  return (
    <>
      {/* {!!user && <HubSpot />} */}
      <Routes>
        <Route
          path="/"
          element={
            <UserContent
              element={
                <Suspense>
                  <Dashboard />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="login"
          element={
            <Suspense>
              <Login />
            </Suspense>
          }
        />
        {/*<Route*/}
        {/*  path="signup"*/}
        {/*  element={*/}
        {/*    <Suspense>*/}
        {/*      <Signup />*/}
        {/*    </Suspense>*/}
        {/*  }*/}
        {/*/>*/}
        <Route
          path="password-recovery"
          element={
            <Suspense>
              <PasswordRecovery />
            </Suspense>
          }
        />
        <Route
          path="account"
          element={
            <UserContent
              element={
                <Suspense>
                  <Account />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="extension/login/:session"
          element={
            <UserContent
              element={
                <Suspense>
                  <ExtensionLogin />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="/projects"
          element={
            <UserContent
              element={
                <Suspense>
                  <Projects />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="projects/:projectId"
          element={
            <UserContent
              element={
                <Suspense>
                  <Project />
                </Suspense>
              }
            />
          }
        >
          <Route path="settings" element={<ProjectSettings />} />
          <Route
            element={
              <Suspense>
                <ProjectElements />
              </Suspense>
            }
          >
            <Route index element={<Navigate to="pages" replace />} />
            <Route
              path="pages"
              element={
                <Suspense>
                  <ProjectPagesTable />
                </Suspense>
              }
            />
            <Route
              path="collections"
              element={
                <Suspense>
                  <ProjectCollectionsTable />
                </Suspense>
              }
            />
            <Route
              path="collections/:collectionId"
              element={
                <Suspense>
                  <ProjectCollectionElements />
                </Suspense>
              }
            >
              <Route index element={<Navigate to="items" replace />} />
              <Route
                path="items"
                element={
                  <Suspense>
                    <ProjectCollectionItemsTable />
                  </Suspense>
                }
              />
              <Route
                path="options"
                element={
                  <Suspense>
                    <ProjectCollectionOptionsTable />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="symbols"
              element={
                <Suspense>
                  <ProjectSymbolsTable />
                </Suspense>
              }
            />
            <Route
              path="templates"
              element={
                <Suspense>
                  <ProjectTemplatesTable />
                </Suspense>
              }
            />
            <Route path="*" element={<span>This route doesn't exist</span>} />
          </Route>
          <Route
            path="pages/:pageId/alternative/:locale"
            element={
              <Suspense>
                <PageAlternative />
              </Suspense>
            }
          />
          <Route
            path="collections/:collectionId/items/:itemId/alternative/:locale"
            element={
              <Suspense>
                <CollectionItemAlternative />
              </Suspense>
            }
          />
          <Route
            path="collections/:collectionId/options/:fieldId/alternative/:locale"
            element={
              <Suspense>
                <CollectionOptionAlternative />
              </Suspense>
            }
          />
          <Route
            path="symbols/:symbolId/alternative/:locale"
            element={
              <Suspense>
                <SymbolAlternative />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="verify/email/:token"
          element={
            <UserContent
              element={
                <Suspense>
                  <VerifyEmail />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="password/change/:token"
          element={
            <Suspense>
              <ChangePassword />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
    </>
  );
}
