import { memo, useCallback } from 'react';
import { TextArea } from 'ui';

import type { ChangeEvent } from 'react';
import type { Props } from './TranslationAlternativeText.types';

import style from './TranslationAlternativeText.module.css';

export const TranslationAlternativeText = memo<Props>(
  ({ alternative, setAlternative, shouldFocus }) => {
    const onChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        setAlternative(event.target.value);
      },
      [setAlternative]
    );

    return (
      <div className={style.node}>
        <TextArea
          value={alternative}
          onChange={onChange}
          fullHeight
          ref={(dom) => shouldFocus && dom?.focus()}
        />
      </div>
    );
  }
);
