import { ArrowBackIcon } from 'ui';
import style from './BackButton.module.css';

type Props = {
  text: string;
  a11yLabel?: string;
  onClick: () => void;
};

export const BackButton = ({ text, a11yLabel, onClick }: Props) => {
  return (
    <button
      aria-label={a11yLabel}
      title={a11yLabel}
      onClick={onClick}
      className={style.backButton}
    >
      <ArrowBackIcon />
      <span>{text}</span>
    </button>
  );
};
