import { forwardRef } from 'react';

import { Button } from 'ui';
import { useImageGallery } from '../ImageGallery';

import type { Props } from './TranslationAlternativeImage.types';

import style from './TranslationAlternativeImage.module.css';

export const TranslationAlternativeImage = forwardRef<
  HTMLDivElement | null,
  Props
>(({ alternative, setAlternative }, ref) => {
  const { openImageGallery } = useImageGallery();

  return (
    <div className={style.node}>
      <div className={style.image}>
        {alternative && <img alt="" src={alternative} />}
        <input readOnly className={style.hiddenInput} />
        <div className={style.change} ref={ref}>
          <Button
            size="small"
            look="highlight"
            variant="outlined"
            text={(alternative ? 'Change' : 'Set') + ' image'}
            onClick={() =>
              openImageGallery((image) => setAlternative(image?.url ?? ''))
            }
          />
        </div>
      </div>
    </div>
  );
});
