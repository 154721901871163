import { DeleteIcon } from 'ui';
import type { Props } from './ProjectCard.types';

import style from './ProjectCard.module.css';

export function ProjectCard({ title, image, onDelete, onManageClick }: Props) {
  return (
    <div className={style.card}>
      <div className={style.header}>
        <h4 className={style.title}>{title}</h4>
        <button
          onClick={onDelete}
          className={style.delete}
          aria-label={'Delete project ' + title}
        >
          <DeleteIcon />
        </button>
      </div>
      <div className={style.imageWrapper}>
        {image ? (
          <img src={image} alt={title} />
        ) : (
          <div className={style.placeholder} />
        )}
        <button onClick={onManageClick} className={style.manageButton}>
          Manage project
        </button>
      </div>
    </div>
  );
}
