export const LogoWebflowCicle = () => (
  <svg
    viewBox="-20 -30 99 100"
    width="100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="30" cy="20" r="48" fill="#4353ff" />
    <path
      d="M38.4309 27.549C38.7818 26.3073 43.4518 11.6766 43.4518 11.6766C45.6923 5.657 50.0383 0.0152893 58.2984 0.0152893L46.637 28.8177C44.2076 34.5944 39.6996 39.8852 31.3585 39.8852C31.3585 39.8852 27.8223 15.2398 27.7683 14.6729C27.5524 15.2668 22.5585 28.2239 22.5585 28.2239C20.2101 34.2165 15.7291 39.8582 7.38798 39.8852L0.855469 0.0422821C6.28123 0.0422821 12.9757 4.49627 13.4616 11.8386C13.4616 11.8386 14.2984 26.4423 14.3254 27.603C14.7843 26.3613 20.453 11.6766 20.453 11.6766C22.6935 5.657 26.9315 0.0152893 34.8947 0.0152893C34.8947 0.0152893 38.2959 26.3073 38.4309 27.549Z"
      fill="#fff"
    />
  </svg>
);
