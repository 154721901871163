import { getAuth } from 'firebase/auth';
import type { HTTPClient } from './client';
import type { User, Card } from './types';
import { trackAccountCreation } from 'datalayer';

export const initUser = (
  client: HTTPClient,
  updateAuthState: ({ user }?: { user: User }) => void
) => ({
  create: async (
    data: Pick<User, 'firstName' | 'lastName' | 'email'> & {
      role: string;
      lookingFor: string;
      alreadyTranslated: string;
    }
  ) => {
    const user = await client.post<User>('v1/user', data);
    updateAuthState({ user });
    trackAccountCreation({
      name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      registration_method:
        getAuth(client.firebase).currentUser?.providerData[0]?.providerId ?? '',
      role: data.role,
      account_use_case: data.lookingFor,
      already_translated: data.alreadyTranslated,
    });
    return user;
  },
  get: () => {
    return client.get<User>('v1/user');
  },
  update: async (data: Pick<User, 'firstName' | 'lastName' | 'email'>) => {
    await client.patch('v1/user', data);
    await updateAuthState();
  },
  getCard: () => {
    return client.get<Card>('v1/user/card');
  },
  addCard: async (paymentMethodId: string) => {
    await client.put('v1/user/card', { paymentMethodId });
  },
  updateState: async (update: User['state']) => {
    await client.patch('v1/user/state', update);
    await updateAuthState();
  },
  sendVerificationEmail: async () => {
    await client.delete('v1/user/email');
  },
  verifyEmail: async (token: string) => {
    await client.post<void>('v1/user/email', { token });
    await updateAuthState();
  },
  resendVerificationEmail: () => {
    return client.delete<void>('v1/user/email');
  },
  recoverPassword: async (email: string) => {
    await fetch(`${process.env.REACT_APP_API}/v1/user/password`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
  },
  changePassword: (data: { token: string; password: string }) => {
    return fetch(`${process.env.REACT_APP_API}/v1/user/password`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
  getStripeDashboardLink: (returnUrl: string) => {
    return client.post<{ url: string }>('v1/user/stripe', { returnUrl });
  },
});
