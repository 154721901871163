import { CrossIcon } from 'ui/Icon';
import style from './CloseIconButton.module.css';

export const CloseIconButton = ({
  onClick,
  disabled,
  rootClassName,
}: {
  onClick?: () => void;
  disabled?: boolean;
  rootClassName?: string;
}) => {
  return (
    <button
      aria-label="Close"
      onClick={onClick}
      className={style.root + (rootClassName ? ' ' + rootClassName : '')}
      disabled={disabled}
    >
      <CrossIcon />
    </button>
  );
};
