import { memo } from 'react';

// import { Input, FormRow } from 'ui';
import type { Props } from './TranslationNodeImage.types';

import style from './TranslationNodeImage.module.css';

export const TranslationNodeImage = memo<Props>(({ node }) => (
  <div className={style.node}>
    <div className={style.image}>
      <img alt="" src={node.value} />
    </div>
    {/* <div className={style.alt}>
        <FormRow label="Alt text">
          <Input
            fullWidth
            readOnly
            defaultValue={alt?.value}
            placeholder="There is no original alt text"
          />
        </FormRow>
      </div> */}
  </div>
));
