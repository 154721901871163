import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { mutate } from 'swr';

import { useApi } from 'providers';
import {
  Button,
  Checkbox,
  CircularProgress,
  ExternalLinkIcon,
  RocketIcon,
  SuccessIcon,
} from 'ui';

import type { ProjectResponse } from 'providers/api/responseTypes';
import type { BuildError } from './index';

import style from './PublishMenu.module.css';

export const PublishMenu = ({
  project,
  url,
  onOpenErrors,
}: {
  project: ProjectResponse;
  url?: { custom?: string; default: string };
  onOpenErrors: (open: boolean) => void;
}) => {
  const [directPublish, setDirectPublish] = useState(false);
  const [waitingForBuilderResponse, setWaitingForBuilderResponse] =
    useState(false);
  const [buildSuccess, setBuildSuccess] =
    useState<{ warnings?: BuildError[] }>();
  const [publishing, setPublishing] = useState(false);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [buildError, setBuildError] = useState<string>();

  const api = useApi();

  const createPreview = async () => {
    try {
      setBuildError(undefined);
      setWaitingForBuilderResponse(true);
      await mutate(
        `projects/${project.id}`,
        async (project: ProjectResponse) => {
          try {
            const { buildId } = await api.projects.build(project, directPublish);
            return { ...project, currentBuildId: buildId };
          } catch (error: any) {
            throw new Error(error?.message ?? 'Unknown build error');
          }
        },
        { revalidate: false }
      );
    } catch (error: any) {
      setBuildError('New preview could not be created. ' + error?.message);
    } finally {
      setWaitingForBuilderResponse(false);
    }
  };

  const publish = () => {
    setPublishing(true);
    mutate(
      `projects/${project.id}`,
      async (project: ProjectResponse) => {
        await api.projects.publish(project);
        setPublishing(false);
        setPublishSuccess(true);
        return { ...project, liveBuildId: project.stagingBuildId };
      },
      { revalidate: false }
    );
  };

  useEffect(() => {
    if (!project.currentBuildId) return;

    const sse = new EventSource(
      `${process.env.REACT_APP_FIREBASE_DATABASE}/build/${project.id}/${project.currentBuildId}.json`
    );

    sse.addEventListener('put', async (event) => {
      const { data } = JSON.parse(event.data) as {
        data: null | {
          type: 'BUILD_START' | 'BUILD_END';
          warnings?: string[];
          warnings2?: BuildError[];
        };
      };
      console.log(data);
      if (data?.type === 'BUILD_END') {
        mutate(
          `projects/${project.id}`,
          (project: ProjectResponse) => {
            // TODO migration process, it will be: setBuildSuccess({ warnings: data.warnings });
            setBuildSuccess({
              warnings: data.warnings2 || (data.warnings as any),
            });
            if (directPublish) setPublishSuccess(true);
            return {
              ...project,
              stagingBuildId: project.currentBuildId,
              liveBuildId: directPublish
                ? project.currentBuildId
                : project.liveBuildId,
              currentBuildId: null,
            };
          },
          { revalidate: false }
        );
      }
    });

    return () => sse.close();
  }, [project.id, project.currentBuildId, directPublish]);

  return (
    <div className={style.container}>
      <div className={style.head}>
        <h5>PREVIEW LATEST VERSION</h5>
        {url && (
          <a
            href={url.default}
            target="_blank"
            rel="noreferrer"
            className={style.externalLink}
          >
            <ExternalLinkIcon />
          </a>
        )}
      </div>
      <div className={style.bodyBuild}>
        {buildSuccess ? (
          <>
            <div className={style.success}>
              <p>
                <SuccessIcon />
                <span>New preview build ready!</span>
              </p>
              <a
                href={url?.default}
                className={style.link}
                target="_blank"
                rel="noreferrer"
              >
                Open preview
              </a>
            </div>
            {buildSuccess.warnings && (
              <Button
                text={`There have been ${buildSuccess.warnings.length} error/s.`}
                variant="text"
                look="danger"
                size="small"
                onClick={() => onOpenErrors(true)}
              />
            )}
          </>
        ) : project.currentBuildId ? (
          <>
            <span>
              <CircularProgress thickness="thick" size="big" />
            </span>
            <p>This could take a few minutes, please wait.</p>
          </>
        ) : (
          <>
            {buildError && <p className={style.error}>{buildError}</p>}
            <Checkbox
              disabled={
                !url?.custom ||
                waitingForBuilderResponse ||
                Boolean(project.currentBuildId)
              }
              text="Directly publish this new version in my domain."
              onChange={(e) => setDirectPublish(e.target.checked)}
              checked={directPublish}
            />
            <Button
              text="Create a new preview"
              onClick={createPreview}
              look="highlight"
              variant="outlined"
              disabled={
                waitingForBuilderResponse || Boolean(project.currentBuildId)
              }
            />
          </>
        )}
      </div>
      <div className={style.head}>
        <h5>LIVE SITE</h5>
        {url?.custom && (
          <a
            href={url.custom}
            target="_blank"
            rel="noreferrer"
            className={style.externalLink}
          >
            <ExternalLinkIcon />
          </a>
        )}
      </div>
      <div className={style.body}>
        {publishSuccess ? (
          <div className={style.success}>
            <p>
              <SuccessIcon />
              <span>Your site is live!</span>
            </p>
          </div>
        ) : url?.custom ? (
          <Button
            text="Publish in your domain"
            onClick={publish}
            icon={RocketIcon}
            look="highlight"
            variant="outlined"
            progress={publishing}
            disabled={
              publishing ||
              !project.stagingBuildId ||
              project.stagingBuildId === project.liveBuildId
            }
          />
        ) : (
          <Link to={`/projects/${project.id}/settings`} className={style.link}>
            Configure your domain first
          </Link>
        )}
        {url?.custom && (
          <a
            href={url.custom}
            target="_blank"
            rel="noreferrer"
            className={style.link}
          >
            {url.custom}
          </a>
        )}
      </div>
    </div>
  );
};
