const segmentPromise = (async () => {
  if (process.env.REACT_APP_DISABLE_TRACK_EVENTS === 'true') {
    return null;
  }

  const { AnalyticsBrowser } = await import('@segment/analytics-next');

  const [tracker] = await AnalyticsBrowser.load({
    writeKey: process.env.REACT_APP_SEGMENT_TOKEN!,
  });

  return tracker;
})();

const track = (
  eventName: string,
  eventProps?: Record<string, string | number | boolean>
) => {
  segmentPromise
    .then((segment) => segment?.track(eventName, eventProps))
    .catch(() => {});
};

export const identifyUserForTracking = (id: string) => {
  segmentPromise.then((segment) => segment?.identify(id)).catch(() => {});
};

export function trackPageView() {
  track('Page View');
}

export function trackAccountCreation(data: {
  name: string;
  last_name: string;
  email: string;
  registration_method: string;
  role: string;
  account_use_case: string;
  already_translated: string;
}) {
  track('Account Created', data);
}

export function trackProjectDeletion(data: {
  project_id: string;
  project_name: string;
  default_language: string;
  number_of_pages: number;
  CMS_collections: number;
  symbols: number;
}) {
  track('Project Deleted', data);
}

export function trackProjectCreation(data: {
  project_id: string;
  project_name: string;
  default_language: string;
  number_of_pages: number;
  CMS_collections: number;
  symbols: number;
}) {
  track('Project Created', data);
}

export function trackPreviewCreation(data: {
  publish: boolean;
  project_id: string;
  project_name: string;
  default_language: string;
  number_of_pages: number;
  CMS_collections: number;
  symbols: number;
}) {
  track('Preview Created', data);
}

export function trackProjectPublication(data: {
  from_preview: boolean;
  project_id: string;
  project_name: string;
  default_language: string;
  number_of_pages: number;
  CMS_collections: number;
  symbols: number;
}) {
  track('Project Published', data);
}

export function trackPlanUpgradedAttempt(data: {
  new_plan: string;
  project_id: string;
  project_name: string;
  default_language: string;
  number_of_pages: number;
  CMS_collections: number;
  symbols: number;
}) {
  track('Plan Upgraded Attempt', data);
}

export function trackCustomDomainConfigured(data: {
  project_id: string;
  project_name: string;
  default_language: string;
  number_of_pages: number;
  CMS_collections: number;
  symbols: number;
}) {
  track('Custom Domain Configured', data);
}

export function trackLogin(data: { uid: string; email: string }) {
  track('Signed In', data);
}

export function trackWelcomeOnBoardingStarted() {
  track('Welcome Onboarding Started');
}

export function trackWelcomeOnBoardingStep(step: number) {
  track('Welcome Onboarding Step', { step });
}

export function trackWelcomeOnBoardingFinished() {
  track('Welcome Onboarding Finished');
}
