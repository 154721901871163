import type { ReactNode } from 'react';
import {
  Root,
  Trigger,
  Portal,
  // Overlay,
  Content as RawContent,
  Title,
  Description,
  Close,
  DialogProps,
} from '@radix-ui/react-dialog';

import { Button, CloseIconButton } from 'ui';
import style from './Dialog.module.css';

function Content({
  children,
  size = 'm',
  noPadding,
  ...props
}: {
  children: ReactNode;
  size?: 'm' | 'l' | 'xl' | 'xxl';
  noPadding?: boolean;
}) {
  return (
    // There is an issue with react-remove-scroll-bar used in radix when a popover is used together with a dialog.
    // Temporarily using a div instead of Overlay to avoid the issue.
    // https://github.com/radix-ui/primitives/blob/6da75e0dbb2d1aebd2dae5a044c595bca39a2347/packages/react/dialog/src/Dialog.tsx#L201-L219
    // https://github.com/radix-ui/primitives/issues/1159#issuecomment-1105320294
    <Portal>
      {/* <Overlay className={style.overlay} /> */}
      <div className={style.overlay} />
      <RawContent
        {...props}
        className={style[size] + (noPadding ? '' : ' ' + style.contentPadding)}
      >
        {children}
      </RawContent>
    </Portal>
  );
}

export const Dialog = ({
  trigger,
  title,
  description,
  size,
  children,
  closeButton,
  noPadding,
  ...props
}: {
  /**
   * The element to trigger the dialog.
   */
  trigger?: ReactNode;
  title?: string | JSX.Element;
  description?: string;
  /**
   * Default 'm'
   */
  size?: 'm' | 'l' | 'xl' | 'xxl';
  children?: ReactNode;
  closeButton?: string;
  noPadding?: boolean;
} & DialogProps) => (
  <Root {...props}>
    <Trigger asChild>{trigger}</Trigger>
    <Content size={size} noPadding={noPadding}>
      {title && <Title className={style.title}>{title}</Title>}
      {description && (
        <Description className={style.description}>{description}</Description>
      )}
      <div className={style.body + (noPadding ? '' : ' ' + style.bodyPadding)}>
        {children}
      </div>
      {closeButton && (
        <div
          style={{ display: 'flex', marginTop: 25, justifyContent: 'flex-end' }}
        >
          <Close asChild>
            <Button look="highlight" aria-label="Close" text={closeButton} />
          </Close>
        </div>
      )}
      {props.onOpenChange && (
        <Close asChild className={style.close}>
          <span>
            <CloseIconButton />
          </span>
        </Close>
      )}
    </Content>
  </Root>
);
