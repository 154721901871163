import { memo, createContext, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'providers';
import { SpinnerContent } from 'ui';
import WipView from '../../views/WIP';

import type { User } from 'providers/api/types';
import type { Props } from './UserContent.types';

const context = createContext(null as any as User);
export const useUser = () => useContext(context);

export const UserContent = memo<Props>(({ element }) => {
  const { isChecking, user, needsMigration } = useAuth();
  const location = useLocation();

  if (isChecking) {
    return <SpinnerContent />;
  }

  if (needsMigration) {
    return <WipView />;
  }

  if (!user) {
    if (location.pathname !== '/') {
      window.sessionStorage.setItem('pathAfterLogin', location.pathname);
    } else {
      window.sessionStorage.removeItem('pathAfterLogin');
    }

    return <Navigate to="/login" />;
  }

  return <context.Provider value={user}>{element}</context.Provider>;
});
