import type { ReactNode } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useAuth, useApi } from 'providers';
import {
  Button,
  DropdownMenu,
  ChromeIcon,
  LogoPolyflowText,
  BagIcon,
  Popover,
  Tooltip,
  QuestionIcon,
  AlertIcon,
} from 'ui';

import style from './Layout.module.css';

export const Layout = ({
  children,
  showTutorialsTip,
  setShowTutorialsTip,
  restartTutorial,
}: {
  children: ReactNode;
  /**
   * Provide the project id of the banner.
   */
  showBanner?: string;
  showTutorialsTip?: boolean;
  setShowTutorialsTip?: (showTip: boolean) => void;
  restartTutorial?: () => void;
}) => {
  const { user = null, hasExtension } = useAuth();
  const api = useApi();

  const navigate = useNavigate();

  return (
    user && (
      <div className={style.root}>
        {/* {showBanner && (
          <div className={style.banner}>
            <strong>Free mode - ON!</strong> Upgrade to translate all your CMS
            collections and publish your project.{' '}
            <Link to={`/projects/${showBanner}/settings`}>Upgrade here</Link>.
          </div>
        )} */}
        <header
          className={
            style.header /* + (showBanner ? ' ' + style.bannerOffset : '') */
          }
        >
          <div className={style.head}>
            <div>
              <Link to="/" className={style.logo}>
                <LogoPolyflowText />
              </Link>
            </div>
            <hr className={style.headDivider} />
            <div>
              <DropdownMenu
                trigger={
                  <div className={style.profile}>
                    {/* <img
                      alt="avatar"
                      className={style.avatar}
                      src={`https://source.boringavatars.com/marble/40/${user.id}?square=true`}
                    /> */}
                    <div className={style.names}>
                      <span>{user.firstName}</span>
                      <span>
                        {user.email}{' '}
                        {!user.isEmailVerified && (
                          <Tooltip text="Email address not validated">
                            <span>
                              <AlertIcon />
                            </span>
                          </Tooltip>
                        )}
                      </span>
                    </div>
                  </div>
                }
                contents={[
                  { text: 'My account', onClick: () => navigate('/account') },
                  { text: 'Logout', onClick: api.auth.signOut },
                ]}
                offset={20}
                noArrow
              />
            </div>
          </div>
          <div className={style.nav}>
            {!hasExtension && (
              <Button
                size="small"
                text="Get the extension"
                icon={ChromeIcon}
                look="highlight"
                onClick={() =>
                  window.open(
                    'https://chrome.google.com/webstore/detail/polyflow/oodggemakpengpkkihcnmlicoiiedmmc?hl=en'
                  )
                }
              />
            )}
            <Link to="/projects" className={style.navItem}>
              <BagIcon />
              My projects
            </Link>
            <Popover
              anchor={
                <DropdownMenu
                  trigger={
                    <button className={style.navItem}>
                      <QuestionIcon /> Help
                    </button>
                  }
                  contents={[
                    {
                      text: 'Documentation',
                      onClick: () => window.open('https://guides.polyflow.co/'),
                    },
                    {
                      text: 'Restart welcome onboarding',
                      onClick: restartTutorial,
                    },
                  ]}
                />
              }
              open={showTutorialsTip}
              color="rgba(44, 97, 243, 1)"
            >
              <p className={style.helpTip}>
                If you ever need help, this is where to go for tutorials.
              </p>
              <button
                className={style.dismissHelpTip}
                onClick={
                  setShowTutorialsTip && (() => setShowTutorialsTip(false))
                }
              >
                Got it
              </button>
            </Popover>
            <a href="https://www.polyflow.co/en/support" target="_blank" rel="noreferrer" className={style.navItem}>
              Support
            </a>
          </div>
        </header>
        <main className={style.body}>{children}</main>
      </div>
    )
  );
};
